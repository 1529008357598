import { Case } from './components/Case/Case';
import { useEntity } from './hooks/useEntity/useEntity';
import { ctw } from '../../common/utils/ctw/ctw';
import { Card } from '../../common/components/atoms/Card/Card';
import { CardContent } from '../../common/components/atoms/Card/Card.Content';
import { Manager } from './Manager';
import { Decision } from './Decision';

export const Entity = () => {
  const { workflow, selectedEntity, tasks, cells, isLoading } = useEntity();

  // Selected entity
  console.log('8888888888888');
  console.log(tasks);
  console.log('8888888888888');
  const approversz = [
    { name: 'Kostas Papajohn', comment: 'Testing out some stuff.', status: 'approve' },
    { name: 'Christopher Petsis', comment: 'Approved!', status: 'Rejected' },
    { name: 'Manager Manager', comment: 'Rayven - Accounting 2.0', status: 'approve' },
  ];
  const approvers = workflow.context.approverChain;
  const tasksz = tasks.slice(1);

  // const calculateTimeDifference = (pastTime) => {
  //   const currentTime = new Date();
  //   const timeDifference = currentTime - pastTime;

  //   // Calculate days, hours, and minutes
  //   const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //   const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  //   // Construct the rough time past string
  //   let roughTimePast = '';
  //   if (days > 0) {
  //     roughTimePast += `${days} days `;
  //   }
  //   if (hours > 0) {
  //     roughTimePast += `${hours} hours `;
  //   }
  //   if (minutes > 0) {
  //     roughTimePast += `${minutes} minutes `;
  //   }

  //   return roughTimePast;
  // };
  return (
    <Case>
      {/* Reject and approve header */}
      <Case.Actions
        assigneeId={workflow?.assignee?.id}
        achain={workflow?.context?.entity?.achain}
        id={workflow.id}
        fullName={selectedEntity.name}
        avatarUrl={selectedEntity.avatarUrl}
        showResolutionButtons={workflow.workflowDefinition.config?.workflowLevelResolution}
      />
      <Case.Content key={selectedEntity?.id}>
        <Card key={0} className={`me-4`}>
          <CardContent
            className={ctw('grid gap-2', {
              'grid-cols-2': tasks[0]?.some(field => field?.type === 'multiDocuments'),
            })}
          >
            {tasks[0]?.map((field, index) => {
              const Cell = cells[field?.type];

              return <Cell key={index} {...field} />;
            })}
          </CardContent>
        </Card>
        {approvers.map(approver => (
          <Manager
            key={approver.name}
            name={approver.name}
            comment={approver.comment}
            status={approver.status}
            time={approver.time}
          />
        ))}
        {workflow.status != 'completed' && (
          <Manager
            key={workflow.assignee.firstName + ' ' + workflow.assignee.lastName}
            name={workflow.assignee.firstName + ' ' + workflow.assignee.lastName}
            comment={''}
            status={'wait'}
            time={''}
          />
        )}
        {/* <Decision /> */}

        {Array.isArray(tasksz) &&
          tasksz?.length > 0 &&
          tasksz?.map((task, index) => {
            if (!Array.isArray(task) || !task?.length) return;

            return (
              <Card key={index} className={`me-4`}>
                <CardContent
                  className={ctw('grid gap-2', {
                    'grid-cols-2': task?.some(field => field?.type === 'multiDocuments'),
                  })}
                >
                  {task?.map((field, index) => {
                    const Cell = cells[field?.type];

                    return <Cell key={index} {...field} />;
                  })}
                </CardContent>
              </Card>
            );
          })}
        {!isLoading && !tasks?.length && (
          <div className={`p-2`}>
            <h2 className={`mt-4 text-6xl`}>No tasks were found</h2>
          </div>
        )}
      </Case.Content>
    </Case>
  );
};
