import 'react-image-crop/dist/ReactCrop.css';
import { FunctionComponent } from 'react';
import { ImageViewer } from '../../../../common/components/organisms/ImageViewer/ImageViewer';
import { IDocumentsProps } from './interfaces';
import { CheckSvg, XMarkSvg } from '../../../../common/components/atoms/icons';
import { useDocuments } from './hooks/useDocuments/useDocuments';
import { ctw } from '../../../../common/utils/ctw/ctw';
import ReactCrop from 'react-image-crop';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { FileText } from 'lucide-react';

/**
 * @description To be used by {@link Case}, and be wrapped by {@link Case.Content}. Displays a single entity's documents using {@link ImageViewer}. Displays documents[0].imageUrl if no document was selected yet.
 *
 * @param props
 * @param props.documents - An array of objects containing the document's image URL and caption to pass into {@link ImageViewer.Item}.
 *
 * @see {@link ImageViewer}
 *
 * @constructor
 */
export const Documents: FunctionComponent<IDocumentsProps> = ({ documents, isLoading }) => {
  const {
    crop,
    onCrop,
    onCancelCrop,
    isCropping,
    onOcr,
    selectedImageRef,
    initialImage,
    skeletons,
    isLoadingOCR,
    selectedImage,
    onSelectImage,
    documentRotation,
    onRotateDocument,
    onTransformed,
    isRotatedOrTransformed,
  } = useDocuments(documents);
  console.log('pucciiii');
  console.log(documents);

  if (documents.length <= 0) {
    return (
      <>
        <div className="flex h-[75%] flex-col items-center justify-center">
          <svg
            width={220}
            height={34}
            viewBox="182.11 328.99 659.77 110.02"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <linearGradient x1="30%" x2="-10%" y1="120%" y2="30%" spreadMethod="pad">
              <stop offset="0%" stopColor="#fff" stopOpacity={1} />
              <stop offset="100%" stopColor="#000" stopOpacity={1} />
            </linearGradient>
            <linearGradient
              x1="30%"
              x2="-10%"
              y1="120%"
              y2="30%"
              gradientTransform="rotate(-30)"
              spreadMethod="pad"
            >
              <stop offset="0%" stopColor="#fff" stopOpacity={1} />
              <stop offset="50%" stopColor="#ccc" stopOpacity={1} />
              <stop offset="100%" stopColor="#000" stopOpacity={1} />
            </linearGradient>
            <g>
              <image width={480} height={480} x={272} y={144} display="none" />
              <g>
                <image x={0} y={0} display="none" />
                <g
                  fill="currentColor"
                  stroke="#252322"
                  strokeLinejoin="miter"
                  strokeMiterlimit={2}
                  strokeWidth={0}
                  fontFamily="Brandmark Sans 21 Italic"
                  fontSize={72}
                  fontStyle="normal"
                  fontWeight="normal"
                  textAnchor="middle"
                  style={{
                    lineHeight: 1,
                    fontVariantLigatures: 'normal',
                    textAlign: 'center',
                  }}
                >
                  <path
                    d="M290.45 354.842h-77.795l-30.541 84.168h25.851l7.094-19.48-10.58-6.372h32.344l16.473 25.852h25.851l-16.472-25.852h6.492c10.702 0 22.606-8.777 26.574-19.479l6.973-19.358c3.968-10.822-1.563-19.48-12.264-19.48zm-71.904 19.479h58.317l-7.094 19.358h-45.33l4.689-12.986z"
                    style={{
                      lineHeight: 1,
                      fontVariantLigatures: 'normal',
                      textAlign: 'center',
                    }}
                  />
                  <path
                    d="M319.882 374.32l-23.567 64.69h25.851l21.163-58.317-10.581-6.372h58.316l-9.379 25.851H349.22c-3.366 9.379 3.608 19.359 13.588 19.359h11.783l-7.094 19.479h25.851l23.568-64.69c3.967-10.82-1.564-19.478-12.265-19.478h-58.316c-10.702 0-22.485 8.657-26.453 19.479z"
                    style={{
                      lineHeight: 1,
                      fontVariantLigatures: 'normal',
                      textAlign: 'center',
                    }}
                  />
                  <path
                    d="M432.439 354.842l13.467 51.823-11.784 32.345h25.972l11.784-32.345 51.222-51.823h-25.972l-30.661 31.022-8.176-31.022z"
                    style={{
                      lineHeight: 1,
                      fontVariantLigatures: 'normal',
                      textAlign: 'center',
                    }}
                  />
                  <path
                    d="M553.727 354.842h-25.852l8.176 84.168h25.972l91.864-110.02h-25.852l-68.777 82.244z"
                    style={{
                      lineHeight: 1,
                      fontVariantLigatures: 'normal',
                      textAlign: 'center',
                    }}
                  />
                  <path
                    d="M632.111 439.01h64.81l7.094-19.48h-58.317l2.285-6.372-10.581-6.493h58.316l7.094-19.479h-45.33l2.405-6.493-10.702-6.372h71.303l7.094-19.48h-64.81c-10.7 0-22.484 8.658-26.452 19.48l-16.473 45.21c-3.968 10.822 1.563 19.479 12.264 19.479z"
                    style={{
                      lineHeight: 1,
                      fontVariantLigatures: 'normal',
                      textAlign: 'center',
                    }}
                  />
                  <path
                    d="M770.704 354.842h-25.972l-30.54 84.168h25.851l19.479-53.507 25.851 53.507h25.852l30.661-84.168h-25.851l-19.48 53.507z"
                    style={{
                      lineHeight: 1,
                      fontVariantLigatures: 'normal',
                      textAlign: 'center',
                    }}
                  />
                </g>
                <image x={0} y={0} display="none" />
              </g>
            </g>
          </svg>
          <h1>No Invoice has been provided by the user yet.</h1>
        </div>
      </>
    );
  } else {
    return (
      <ImageViewer selectedImage={selectedImage} onSelectImage={onSelectImage}>
        <div className={`flex min-h-[600px] w-full flex-col items-center`}>
          <div
            className={ctw(
              `
            d-full relative flex justify-center rounded-md`,
            )}
          >
            <TransformWrapper onTransformed={onTransformed}>
              <TransformComponent
                wrapperClass={`max-w-[90%]`}
                contentClass={ctw(`overflow-x-auto`, {
                  'hover:cursor-move': selectedImage?.fileType !== 'pdf',
                })}
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                }}
                contentStyle={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <ReactCrop
                  crop={crop}
                  onChange={onCrop}
                  disabled={
                    !isCropping || selectedImage?.fileType === 'pdf' || isRotatedOrTransformed
                  }
                  className={ctw({
                    'd-full [&>div]:d-full': selectedImage?.fileType === 'pdf',
                    'rotate-90': documentRotation === 90,
                    'rotate-180': documentRotation === 180,
                    'rotate-[270deg]': documentRotation === 270,
                  })}
                >
                  <ImageViewer.SelectedImage
                    key={initialImage?.imageUrl}
                    initialImage={initialImage}
                    ref={selectedImageRef}
                    isLoading={isLoading}
                  />
                </ReactCrop>
              </TransformComponent>
            </TransformWrapper>
            <div className={`absolute z-50 flex space-x-2 bottom-right-6`}>
              {selectedImage?.fileType !== 'pdf' && (
                <>
                  <button
                    type={`button`}
                    className={ctw(
                      `btn-ghost btn-sm btn-circle btn bg-base-300/70 text-[0.688rem] focus:outline-primary`,
                    )}
                    onClick={onRotateDocument}
                  >
                    <FileText className={`rotate-90 p-0.5`} />
                  </button>
                  <button
                    className={ctw(
                      'btn-ghost btn-sm btn-circle btn bg-base-300/70 focus:outline-primary',
                      {
                        hidden: !isCropping,
                      },
                    )}
                    onClick={onCancelCrop}
                  >
                    <XMarkSvg className={`p-0.5`} />
                  </button>
                  <div
                    title={
                      isRotatedOrTransformed
                        ? `Cannot OCR rotated, zoomed, panned, or pinched documents`
                        : undefined
                    }
                  >
                    <button
                      type={`button`}
                      className={ctw(
                        `btn-ghost btn-sm btn-circle btn bg-base-300/70 text-[0.688rem] focus:outline-primary`,
                        { loading: isLoadingOCR },
                      )}
                      onClick={onOcr}
                      disabled={isLoading || isRotatedOrTransformed}
                    >
                      {isCropping && !isLoadingOCR && <CheckSvg className={`p-0.5`} />}
                      {!isCropping && !isLoadingOCR && <span className={`p-0.5`}>OCR</span>}
                    </button>
                  </div>
                </>
              )}
              <ImageViewer.ZoomButton disabled={isLoading} />
            </div>
          </div>
        </div>
        <ImageViewer.List>
          {isLoading
            ? skeletons.map(index => (
                <ImageViewer.SkeletonItem key={`image-viewer-skeleton-${index}`} />
              ))
            : documents.map(({ imageUrl, title, fileType }) => (
                <ImageViewer.Item
                  key={`${imageUrl}-${title}`}
                  src={imageUrl}
                  fileType={fileType}
                  alt={title}
                  caption={title}
                />
              ))}
        </ImageViewer.List>
        <ImageViewer.ZoomModal />
      </ImageViewer>
    );
  }
};
