import { useQuery } from '@tanstack/react-query';
import { workflowsQueryKeys } from '../../../query-keys';

export const useWorkflowQuery = ({
  workflowId,
  filterId,
}: {
  workflowId: string;
  filterId: string;
}) => {
  return useQuery({
    ...workflowsQueryKeys.byId({ workflowId, filterId }),
    enabled: !!filterId && !!workflowId,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });
};
