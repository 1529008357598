import { useSearch } from '../../../../common/hooks/useSearch/useSearch';
import { ChangeEventHandler, useCallback } from 'react';
import { createArrayOfNumbers } from '../../../../common/utils/create-array-of-numbers/create-array-of-numbers';
import { useSelectEntityOnMount } from '../../../../domains/entities/hooks/useSelectEntityOnMount/useSelectEntityOnMount';
import { useWorkflowsQuery } from '../../../../domains/workflows/hooks/queries/useWorkflowsQuery/useWorkflowsQuery';
import { useSearchParamsByEntity } from '../../../../common/hooks/useSearchParamsByEntity/useSearchParamsByEntity';
import { useEntityType } from '../../../../common/hooks/useEntityType/useEntityType';

export const useEntities = () => {
  const [{ filterId, filter, sortBy, sortDir, page, pageSize }, setSearchParams] =
    useSearchParamsByEntity();

  const { data, isLoading } = useWorkflowsQuery({
    filterId,
    filter,
    sortBy,
    sortDir,
    page,
    pageSize,
  });

  /*
  const { data, isLoading } = {
    status: 'success',
    fetchStatus: 'idle',
    isLoading: false,
    isSuccess: true,
    isError: false,
    isInitialLoading: false,
    data: {
      data: [
        {
          id: 'cljzknxll0007ot37kt4o2t8s',
          status: 'active',
          createdAt: '2023-07-12T10:22:15.514Z',
          entity: {
            id: 'cljzknpj00000ot37098q50po',
            name: 'Papi Papajohn',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/210.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: {
            id: 'cljzk79aq0006ot06rtr5qs0v',
            firstName: 'Arno',
            lastName: 'Bernhard',
          },
        },
        {
          id: 'cljzk79fl005bot06uzcbfjiq',
          status: 'active',
          createdAt: '2023-07-12T07:51:32.355Z',
          entity: {
            id: 'ckkt3r0v40002qxtt8sxk7fvi',
            name: 'biggie Grant',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1228.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: {
            id: 'cljzk79aq0006ot06rtr5qs0v',
            firstName: 'Arno',
            lastName: 'Bernhard',
          },
        },
        {
          id: 'cljzk79fs005lot06fvm8c7wo',
          status: 'active',
          createdAt: '2023-07-12T05:06:48.042Z',
          entity: {
            id: 'ckkt3s3ha0005qxttdz5yxg7p',
            name: 'Julianne Hessel',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/561.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gc0073ot06cnbv62f7',
          status: 'active',
          createdAt: '2023-07-12T04:06:59.042Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4f',
            name: 'Willie Huels',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/279.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gx008zot06gb75enlu',
          status: 'active',
          createdAt: '2023-07-12T03:14:13.098Z',
          entity: {
            id: 'ckkt3rhxr0003qxtt5x6h5j1w',
            name: 'Edwardo Bednar',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/500.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79g3005zot06mvhgdk73',
          status: 'active',
          createdAt: '2023-07-12T00:49:11.236Z',
          entity: {
            id: 'ckkt3sc1n0006qxtt9e9u7y6z',
            name: 'Arvid Kutch',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1116.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gd0077ot067pdujg2d',
          status: 'active',
          createdAt: '2023-07-11T19:45:14.796Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4i',
            name: 'Carmel Reichel',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/847.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gj007not06vqguzrpp',
          status: 'active',
          createdAt: '2023-07-11T19:14:13.546Z',
          entity: {
            id: 'ckkt3sjz40007qxtt3nqj80jt',
            name: 'Una Barton',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/869.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79g7006jot06l1stswr0',
          status: 'active',
          createdAt: '2023-07-11T19:04:35.164Z',
          entity: {
            id: 'ckkt3swf20009qxttgx0p6x60',
            name: 'Erica Grimes',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/613.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79h5009dot06kwl4nw7y',
          status: 'active',
          createdAt: '2023-07-11T17:09:03.078Z',
          entity: {
            id: 'ckkt3rv4z0004qxtte4vz9e9e',
            name: 'Ian Howell',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/146.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79g30067ot06s05la8gk',
          status: 'active',
          createdAt: '2023-07-11T05:22:45.805Z',
          entity: {
            id: 'ckkt3st0r0008qxtt5rxj0wgg',
            name: 'Ima Leannon',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/805.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gu008tot06fnox8sjh',
          status: 'active',
          createdAt: '2023-07-10T20:56:45.520Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4d',
            name: 'Meagan Kuphal',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/157.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gb006vot06x1ciy7da',
          status: 'active',
          createdAt: '2023-07-10T20:45:15.326Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4c',
            name: 'Giles Reichert',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1082.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gq008lot06ybuj645t',
          status: 'active',
          createdAt: '2023-07-10T18:49:20.535Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4h',
            name: 'Luna Roberts',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1232.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79go008dot06hao209kg',
          status: 'active',
          createdAt: '2023-07-10T18:10:19.309Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4e',
            name: 'Vilma King',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/187.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gp008hot068hro7fpz',
          status: 'active',
          createdAt: '2023-07-10T17:59:00.578Z',
          entity: {
            id: 'ckkt3qnv40001qxtt7nmj9r2r',
            name: 'Gerard Hauck',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/509.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
        {
          id: 'cljzk79gh007fot06c92kyyhf',
          status: 'active',
          createdAt: '2023-07-10T15:01:40.709Z',
          entity: {
            id: 'ckkt3t2bw000aqxtt0hj4pw4g',
            name: 'Bulah Dickinson',
            avatarUrl:
              'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/943.jpg',
            approvalState: 'PROCESSING',
          },
          assignee: null,
        },
      ],
      meta: {
        totalItems: 17,
        totalPages: 1,
      },
    },
    dataUpdatedAt: 1689158918222,
    error: null,
    errorUpdatedAt: 0,
    failureCount: 0,
    failureReason: null,
    errorUpdateCount: 0,
    isFetched: true,
    isFetchedAfterMount: true,
    isFetching: false,
    isRefetching: false,
    isLoadingError: false,
    isPaused: false,
    isPlaceholderData: false,
    isPreviousData: false,
    isRefetchError: false,
    isStale: false,
  };*/
  const {
    meta: { totalPages },
    data: cases,
  } = data || { meta: { totalPages: 0 }, data: [] };
  const entity = useEntityType();
  const individualsSearchOptions = ['entity.name', 'entity.email'];
  const businessesSearchOptions = ['entity.name'];
  const { searched, onSearch, search } = useSearch({
    data: cases,
    searchBy: entity === 'individuals' ? individualsSearchOptions : businessesSearchOptions,
  });

  const onSortDirToggle = useCallback(() => {
    setSearchParams({
      sortDir: sortDir === 'asc' ? 'desc' : 'asc',
    });
  }, [setSearchParams, sortDir]);

  const onSortBy = useCallback(
    (sortBy: string) => {
      setSearchParams({
        sortBy,
      });
    },
    [setSearchParams],
  );

  const onFilterChange = useCallback(
    (key: string) => {
      return (values: string[]) => {
        setSearchParams({
          filter: {
            ...filter,
            [key]: values,
          },
          page: 1,
        });
      };
    },
    [filter, setSearchParams],
  );

  const onPaginate = useCallback(
    (page: number) => () => {
      setSearchParams({
        page,
        pageSize,
      });
    },
    [pageSize, setSearchParams],
  );

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      onSearch(event.target.value);
    },
    [onSearch],
  );
  const onSortByChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    event => {
      onSortBy(event.target.value);
    },
    [onSortBy],
  );
  const skeletonEntities = createArrayOfNumbers(6);

  useSelectEntityOnMount();

  return {
    onPaginate,
    onSearch: onSearchChange,
    onFilter: onFilterChange,
    onSortBy: onSortByChange,
    onSortDirToggle,
    search,
    cases: searched,
    isLoading,
    page,
    totalPages,
    skeletonEntities,
    entity,
  };
};
