import { SubmitHandler, useForm } from 'react-hook-form';
import { Form } from '../../../../common/components/organisms/Form/Form';
import { ctw } from '../../../../common/utils/ctw/ctw';
import { toStartCase } from '../../../../common/utils/to-start-case/to-start-case';
import { camelCaseToSpace } from '../../../../common/utils/camel-case-to-space/camel-case-to-space';
import { Input } from '../../../../common/components/atoms/Input/Input';
import { Button } from '../../../../common/components/atoms/Button/Button';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { AnyRecord } from '../../../../common/types';
import { IEditableDetails } from './interfaces';
import { useUpdateWorkflowByIdMutation } from '../../../../domains/workflows/hooks/mutations/useUpdateWorkflowByIdMutation/useUpdateWorkflowByIdMutation';
import { FormField } from '../../../../common/components/organisms/Form/Form.Field';
import { FormItem } from '../../../../common/components/organisms/Form/Form.Item';
import { FormLabel } from '../../../../common/components/organisms/Form/Form.Label';
import { FormControl } from '../../../../common/components/organisms/Form/Form.Control';
import { FormMessage } from '../../../../common/components/organisms/Form/Form.Message';
// import { SelectItem } from '../../../../common/components/atoms/Select/Select.Item';
// import { SelectContent } from '../../../../common/components/atoms/Select/Select.Content';
// import { SelectTrigger } from '../../../../common/components/atoms/Select/Select.Trigger';
// import { SelectValue } from '../../../../common/components/atoms/Select/Select.Value';
// import { Select } from '../../../../common/components/atoms/Select/Select';
import { useWatchDropdownOptions } from './hooks/useWatchDropdown';
import Select from 'react-select';
import { ChevronDownSvg } from '../../../../common/components/atoms/icons';
import { DetailsGrid } from '../../../../common/components/molecules/DetailsGrid/DetailsGrid';
import { useAuthenticatedUserQuery } from '../../../../domains/auth/hooks/queries/useAuthenticatedUserQuery/useAuthenticatedUserQuery';

// const useInitialCategorySetValue = ({ form, data }) => {
//   useEffect(() => {
//     const categoryValue = form.getValues('category');

//     form.setValue('category', categoryValue);
//   }, [form, data]);
// };

export const EditableDetails: FunctionComponent<IEditableDetails> = ({
  data,
  valueId,
  id,
  documents,
  title,
  workflowId,
}) => {
  const [formData, setFormData] = useState(data);
  const [editable, setEditable] = useState(false);
  const [editAccount, setEditAccount] = useState(false);

  const {
    data: { user: authenticatedUser },
  } = useAuthenticatedUserQuery();

  const stateOptions = [
    {
      value: '11-00-01-0087',
      label: 'ΚΤΙΡΙΑ - ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΚΤΙΡΙΩΝ ΑΓΟΡΕΣ 24%',
      Active: 'Ναι',
      Name: 'ΚΤΙΡΙΑ - ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΚΤΙΡΙΩΝ ΑΓΟΡΕΣ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '11-07-01-0087',
      label: 'ΚΤΙΡΙΑ-ΕΓΚΑΤ.ΚΤΙΡΙΩΝ ΣΕ ΑΚΙΝΗΤΑ ΤΡΙΤΩΝ ΑΓΟΡΕΣ 24%',
      Active: 'Ναι',
      Name: 'ΚΤΙΡΙΑ-ΕΓΚΑΤ.ΚΤΙΡΙΩΝ ΣΕ ΑΚΙΝΗΤΑ ΤΡΙΤΩΝ ΑΓΟΡΕΣ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '12-30-01-0087',
      label: 'ΑΓΟΡΕΣ ΜΗΧΑΝΗΜΑΤΩΝ ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΜΗΧΑΝΗΜΑΤΩΝ ΜΕ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-00-01-0087',
      label: 'ΑΓΟΡΕΣ ΕΠΙΠΛΩΝ ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΠΙΠΛΩΝ ΜΕ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-03-00-0000',
      label: 'Η/Υ ΚΑΙ ΗΛΕΚΤΡΟΝΙΚΑ ΣΥΓΚΡΟΤΗΜΑΤΑ ΑΠΟΓΡΑΦΗΣ',
      Active: 'Ναι',
      Name: 'Η/Υ ΚΑΙ ΗΛΕΚΤΡΟΝΙΚΑ ΣΥΓΚΡΟΤΗΜΑΤΑ ΑΠΟΓΡΑΦΗΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-03-01-0087',
      label: 'Η/Υ ΚΑΙ ΗΛΕΚΤΡΟΝΙΚΑ ΣΥΓΚΡΟΤΗΜΑΤΑ ΑΓΟΡΕΣ ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'Η/Υ ΚΑΙ ΗΛΕΚΤΡΟΝΙΚΑ ΣΥΓΚΡΟΤΗΜΑΤΑ ΑΓΟΡΕΣ ΜΕ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-08-00-0000',
      label: 'ΕΞΟΠΛΙΣΜΟΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΩΝ ΑΠΟΓΡΑΦΗΣ',
      Active: 'Ναι',
      Name: 'ΕΞΟΠΛΙΣΜΟΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΩΝ ΑΠΟΓΡΑΦΗΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-09-00-0000',
      label: 'ΛΟΙΠΟΣ ΕΞΟΠΛΙΣΜΟΣ ΑΠΟΓΡΑΦΗΣ',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΟΣ ΕΞΟΠΛΙΣΜΟΣ ΑΠΟΓΡΑΦΗΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-09-01-0000',
      label: 'ΛΟΙΠΟΣ ΕΞΟΠΛΙΣΜΟΣ ΑΓΟΡΕΣ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΟΣ ΕΞΟΠΛΙΣΜΟΣ ΑΓΟΡΕΣ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-09-01-0087',
      label: 'ΛΟΙΠΟΣ ΕΞΟΠΛΙΣΜΟΣΑΓΟΡΕΣ ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΟΣ ΕΞΟΠΛΙΣΜΟΣΑΓΟΡΕΣ ΜΕ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-30-01-0087',
      label: 'ΕΠΙΠΛΑ ΑΓΟΡΕΣ 24%',
      Active: 'Ναι',
      Name: 'ΕΠΙΠΛΑ ΑΓΟΡΕΣ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '14-99-00-0000',
      label: 'ΑΠΟΣΒΕΣΜΕΝΑ ΕΠΙΠΛΑ',
      Active: 'Ναι',
      Name: 'ΑΠΟΣΒΕΣΜΕΝΑ ΕΠΙΠΛΑ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '15-01-01-0087',
      label: 'ΚΤΙΡΙΑ-ΕΓΚΑΤ. ΚΤΙΡ.-ΤΕΧΝ. ΕΡΓΑ ΥΠΟ ΕΚΤ. ΑΓΟΡΕΣ ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΚΤΙΡΙΑ-ΕΓΚΑΤ. ΚΤΙΡ.-ΤΕΧΝ. ΕΡΓΑ ΥΠΟ ΕΚΤ. ΑΓΟΡΕΣ ΜΕ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-28-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '18-11-00-0001',
      label: 'ΔΟΣΜΕΝΕΣ ΕΓΓΥΗΣΕΙΣ ΣΕ ΕΝΟΙΚΙΑ ΚΤΙΡΙΩΝ',
      Active: 'Ναι',
      Name: 'ΔΟΣΜΕΝΕΣ ΕΓΓΥΗΣΕΙΣ ΣΕ ΕΝΟΙΚΙΑ ΚΤΙΡΙΩΝ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '18-11-00-0003',
      label: 'ΔΟΣΜΕΝΕΣ ΕΓΓΥΗΣΕΙΣ ΣΕ ΗΡΩΝ',
      Active: 'Ναι',
      Name: 'ΔΟΣΜΕΝΕΣ ΕΓΓΥΗΣΕΙΣ ΣΕ ΗΡΩΝ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '18-11-00-0004',
      label: 'ΔΟΣΜΕΝΕΣ ΕΓΓΥΗΣΕΙΣ ΣΕ NRG',
      Active: 'Ναι',
      Name: 'ΔΟΣΜΕΝΕΣ ΕΓΓΥΗΣΕΙΣ ΣΕ NRG',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '20-00-01-0000',
      label: 'ΕΜΠΟΡΕΥΜΑΤΑ ΑΠΟΘΕΜΑΤΑ ΑΠΟΓΡΑΦΗΣ ΕΝΑΡΞΕΩΣ',
      Active: 'Ναι',
      Name: 'ΕΜΠΟΡΕΥΜΑΤΑ ΑΠΟΘΕΜΑΤΑ ΑΠΟΓΡΑΦΗΣ ΕΝΑΡΞΕΩΣ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '20-01-01-0087',
      label: 'ΑΓΟΡΕΣ ΕΜΠΟΡΕΥΜΑΤΩΝ ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΜΠΟΡΕΥΜΑΤΩΝ ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-20-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '24-00-01-0000',
      label: 'ΑΠΟΘΕΜΑΤΑ ΑΠΟΓΡΑΦΗΣ ΕΝΑΡΞΕΩΣ ΠΡΩΤΕΣ & ΒΟΗΘΗΤΙΚΕΣ ΥΛΕΣ-ΥΛΙΚΑ',
      Active: 'Ναι',
      Name: 'ΑΠΟΘΕΜΑΤΑ ΑΠΟΓΡΑΦΗΣ ΕΝΑΡΞΕΩΣ ΠΡΩΤΕΣ & ΒΟΗΘΗΤΙΚΕΣ ΥΛΕΣ-ΥΛΙΚΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '24-01-01-0000',
      label: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. ΧΩΡΙΣ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. ΧΩΡΙΣ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '24-01-01-0025',
      label: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. 6%',
      Active: 'Ναι',
      Name: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-24-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '24-01-01-0036',
      label: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. 13%',
      Active: 'Ναι',
      Name: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-24-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '24-01-01-0087',
      label: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. 24%',
      Active: 'Ναι',
      Name: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΑΓΟΡΕΣ ΕΣΩΤ. 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-24-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '24-98-01-0036',
      label: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΕΚΠΤΩΣΕΙΣ-ΕΠΙΣΤΡ. 13%',
      Active: 'Ναι',
      Name: 'ΠΡΩΤΕΣ & ΒΟΗΘ. ΥΛΕΣ-ΥΛΙΚΑ ΣΥΣΚ. ΕΚΠΤΩΣΕΙΣ-ΕΠΙΣΤΡ. 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-24-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-00-02-0036',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 13%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-25-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-00-02-0087',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-25-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-01-02-0087',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-25-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-02-02-0036',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 13%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-25-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-05-02-0025',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 6%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-25-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-05-02-0036',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 13%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-25-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '25-05-02-0087',
      label: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΑΓΟΡΕΣ ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-25-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '30-00-00-0000',
      label: 'ΠΕΛΑΤΕΣ ΧΟΝΔΡΙΚΗΣ',
      Active: 'Ναι',
      Name: 'ΠΕΛΑΤΕΣ ΧΟΝΔΡΙΚΗΣ',
      AccountType: 'Πελάτης',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '30-01-00-0000',
      label: 'ΠΕΛΑΤΕΣ ΕΞΩΤΕΡΙΚΟΥ Ε.Ε',
      Active: 'Ναι',
      Name: 'ΠΕΛΑΤΕΣ ΕΞΩΤΕΡΙΚΟΥ Ε.Ε',
      AccountType: 'Πελάτης',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Όχι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '33-00-00-0000',
      label: 'ΠΡΟΚΑΤΑΒΟΛΕΣ ΠΡΟΣΩΠΙΚΟΥ',
      Active: 'Ναι',
      Name: 'ΠΡΟΚΑΤΑΒΟΛΕΣ ΠΡΟΣΩΠΙΚΟΥ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '33-95-00-0000',
      label: 'ΠΙΣΤΩΤΙΚΕΣ ΚΑΡΤΕΣ',
      Active: 'Ναι',
      Name: 'ΠΙΣΤΩΤΙΚΕΣ ΚΑΡΤΕΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '38-00-00-0000',
      label: 'ΤΑΜΕΙΟ',
      Active: 'Ναι',
      Name: 'ΤΑΜΕΙΟ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Όχι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '38-03-00-0001',
      label: 'ΚΑΤΑΘΕΣΕΙΣ ΟΨΕΩΣ ALPHA BANK  GR92014010301030002320009518',
      Active: 'Ναι',
      Name: 'ΚΑΤΑΘΕΣΕΙΣ ΟΨΕΩΣ ALPHA BANK  GR92014010301030002320009518',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '38-03-00-0002',
      label: 'ΚΑΤΑΘΕΣΕΙΣ ΟΨΕΩΣ OPTIMA  BANK GR1003400310031001034027197',
      Active: 'Ναι',
      Name: 'ΚΑΤΑΘΕΣΕΙΣ ΟΨΕΩΣ OPTIMA  BANK GR1003400310031001034027197',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '40-00-00-0000',
      label: 'ΚΑΤΑΒ/ΜΕΝΟ ΜΕΤΟΧΙΚΟ ΚΕΦΑΛΑΙΟ Κ.ΜΕΤΟΧΩΝ',
      Active: 'Ναι',
      Name: 'ΚΑΤΑΒ/ΜΕΝΟ ΜΕΤΟΧΙΚΟ ΚΕΦΑΛΑΙΟ Κ.ΜΕΤΟΧΩΝ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '40-06-00-0000',
      label: 'ΕΤΑΙΡΙΚΟ ΚΕΦΑΛΑΙΟ',
      Active: 'Ναι',
      Name: 'ΕΤΑΙΡΙΚΟ ΚΕΦΑΛΑΙΟ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '42-01-00-0000',
      label: 'ΥΠΟΛΟΙΠΟ ΖΗΜΙΩΝ ΧΡΗΣΕΩΣ ΕΙΣ ΝΕΟ',
      Active: 'Ναι',
      Name: 'ΥΠΟΛΟΙΠΟ ΖΗΜΙΩΝ ΧΡΗΣΕΩΣ ΕΙΣ ΝΕΟ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '50-00-00-0000',
      label: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΣΩΤΕΡΙΚΟΥ',
      Active: 'Ναι',
      Name: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΣΩΤΕΡΙΚΟΥ',
      AccountType: 'Προμηθευτής',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '50-01-00-0000',
      label: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΞΩΤΕΡΙΚΟΥ Ε.Ε.',
      Active: 'Ναι',
      Name: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΞΩΤΕΡΙΚΟΥ Ε.Ε.',
      AccountType: 'Προμηθευτής',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Όχι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '50-01-01-0000',
      label: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΞΩΤΕΡΙΚΟΥ ΕΚΤΟΣ Ε.Ε.',
      Active: 'Ναι',
      Name: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΞΩΤΕΡΙΚΟΥ ΕΚΤΟΣ Ε.Ε.',
      AccountType: 'Προμηθευτής',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Όχι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '53-00-00-0000',
      label: 'ΑΠΟΔΟΧΕΣ ΠΡΟΣΩΠΙΚΟΥ ΠΛΗΡΩΤΕΕΣ',
      Active: 'Ναι',
      Name: 'ΑΠΟΔΟΧΕΣ ΠΡΟΣΩΠΙΚΟΥ ΠΛΗΡΩΤΕΕΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '53-90-00-0000',
      label: 'ΕΠΙΤΑΓΕΣ ΠΛΗΡΩΤΕΕΣ',
      Active: 'Ναι',
      Name: 'ΕΠΙΤΑΓΕΣ ΠΛΗΡΩΤΕΕΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-20-0025',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ.6%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 6%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-20-0036',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-20-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-24-0025',
      label: "Φ.Π.Α ΑΓΟΡΩΝ Α'ΥΛΩΝ ΕΣΩΤΕΡ.6%",
      Active: 'Ναι',
      Name: "Φ.Π.Α ΑΓΟΡΩΝ Α'ΥΛΩΝ ΕΣΩΤΕΡ. 6%",
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-24-0036',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-24-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-25-0025',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 6%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 6%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-25-0036',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-25-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-28-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΑΓΙΩΝ ΣΤΟΙΧΕΙΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΑΓΙΩΝ ΣΤΟΙΧΕΙΩΝ ΦΠΑ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-29-0024',
      label: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-29-0025',
      label: 'ΦΠΑ ΔΑΠΑΝΩΝ-ΕΞΟΔΩΝ 6%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΔΑΠΑΝΩΝ-ΕΞΟΔΩΝ 6%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-29-0036',
      label: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-29-0087',
      label: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-29-0487',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΝΔΟΚ. ΛΗΨΕΙΣ ΥΠΗΡΕΣ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΝΔΟΚ. ΛΗΨΕΙΣ ΥΠΗΡΕΣ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-70-0087',
      label: 'ΦΠΑ ΕΚΡΟΩΝ ΕΜΠΟΡΕΥΜ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΚΡΟΩΝ ΕΜΠΟΡΕΥΜ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-71-0036',
      label: 'ΦΠΑ ΠΩΛΗΣΕΩΝ ΠΡΟΙΟΝΤΩΝ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΠΩΛΗΣΕΩΝ ΠΡΟΙΟΝΤΩΝ 13%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-71-0087',
      label: 'ΦΠΑ ΠΩΛΗΣ.ΕΤΟΙΜ.ΠΡΟΙΟΝΤ.ΕΣΩΤΕΡ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΠΩΛΗΣ.ΕΤΟΙΜ.ΠΡΟΙΟΝΤ.ΕΣΩΤΕΡ. 24%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-73-0036',
      label: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 13%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-73-0087',
      label: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 24%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-80-0487',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΝΔΟΚ.ΑΠΟΚΤ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΝΔΟΚ.ΑΠΟΚΤ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-90-0087',
      label: 'ΑΝΤΙΘΕΤΟΣ ΦΠΑ ΕΙΣΡΟΩΝ ΠΑΓΙΩΝ ΣΤΟΙΧΕΙΩΝ ΦΠΑ 24% ΑΡΘΡΟ 39Α',
      Active: 'Ναι',
      Name: 'ΑΝΤΙΘΕΤΟΣ ΦΠΑ ΕΙΣΡΟΩΝ ΠΑΓΙΩΝ ΣΤΟΙΧΕΙΩΝ ΦΠΑ 24% ΑΡΘΡΟ 39Α',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-00-99-0000',
      label: 'ΑΠΟΔΟΣΗ ΕΚΚΑΘΑΡΙΣΗ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΑΠΟΔΟΣΗ ΕΚΚΑΘΑΡΙΣΗ ΦΠΑ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-03-00-0000',
      label: 'ΦΟΡΟΣ ΜΙΣΘΩΤΩΝ ΥΠΗΡΕΣΙΩΝ',
      Active: 'Ναι',
      Name: 'ΦΟΡΟΣ ΜΙΣΘΩΤΩΝ ΥΠΗΡΕΣΙΩΝ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-04-00-0000',
      label: 'ΦΟΡΟΣ ΑΜΟΙΒΩΝ ΕΠΙΧΕΙΡΗΜΑΤΙΚΗΣ ΔΡΑΣΤΗΡΙΟΤΗΤΑΣ',
      Active: 'Ναι',
      Name: 'ΦΟΡΟΣ ΑΜΟΙΒΩΝ ΕΠΙΧΕΙΡΗΜΑΤΙΚΗΣ ΔΡΑΣΤΗΡΙΟΤΗΤΑΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-07-00-0001',
      label: 'ΤΕΛΟΣ ΕΠΙΤΙΔΕΥΜΑΤΟΣ ΕΤΗΣΙΑΣ ΔΗΛΩΣΗΣ',
      Active: 'Ναι',
      Name: 'ΤΕΛΟΣ ΕΠΙΤΙΔΕΥΜΑΤΟΣ ΕΤΗΣΙΑΣ ΔΗΛΩΣΗΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '54-09-12-0000',
      label: 'ΦΟΡΟΣ ΑΜΟΙΒ.ΤΡΙΤΩΝ 3%',
      Active: 'Ναι',
      Name: 'ΦΟΡΟΣ ΑΜΟΙΒ.ΤΡΙΤΩΝ 3%',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '55-00-00-0000',
      label: 'ΙΚΑ ΛΟΓΑΡΙΑΣΜΟΣ ΤΡΕΧΟΥΣΑΣ ΚΙΝΗΣΗΣ',
      Active: 'Ναι',
      Name: 'ΙΚΑ ΛΟΓΑΡΙΑΣΜΟΣ ΤΡΕΧΟΥΣΑΣ ΚΙΝΗΣΗΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '55-02-00-0000',
      label: 'ΤΕΚΑ - ΕΠΙΚΟΥΡΙΚΑ ΤΑΜΕΙΑ',
      Active: 'Ναι',
      Name: 'ΤΕΚΑ - ΕΠΙΚΟΥΡΙΚΑ ΤΑΜΕΙΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-00-00-0000',
      label: 'ΤΑΚΤΙΚΕΣ ΑΠΟΔΟΧΕΣ ΕΜΜΙΣΘΟΥ ΠΡΟΣΩΠΙΚΟΥ',
      Active: 'Ναι',
      Name: 'ΤΑΚΤΙΚΕΣ ΑΠΟΔΟΧΕΣ ΕΜΜΙΣΘΟΥ ΠΡΟΣΩΠΙΚΟΥ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-00-03-0000',
      label: 'ΔΩΡΑ ΕΟΡΤΩΝ ΕΜΜΙΣΘΟΥ ΠΡΟΣΩΠΙΚΟΥ',
      Active: 'Ναι',
      Name: 'ΔΩΡΑ ΕΟΡΤΩΝ ΕΜΜΙΣΘΟΥ ΠΡΟΣΩΠΙΚΟΥ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-00-05-0000',
      label: 'ΑΠΟΔΟΧΕΣ ΑΣΘΕΝΕΙΑΣ',
      Active: 'Ναι',
      Name: 'ΑΠΟΔΟΧΕΣ ΑΣΘΕΝΕΙΑΣ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-00-06-0000',
      label: 'ΑΠΟΔΟΧΕΣ ΚΑΝΟΝΙΚΗΣ ΑΔΕΙΑΣ ΕΜΜΙΣΘ.ΠΡΟΣΩΠ',
      Active: 'Ναι',
      Name: 'ΑΠΟΔΟΧΕΣ ΚΑΝΟΝΙΚΗΣ ΑΔΕΙΑΣ ΕΜΜΙΣΘ.ΠΡΟΣΩΠ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-00-07-0000',
      label: 'ΕΠΙΔΟΜΑΤΑ ΚΑΝΟΝ.ΑΔΕΙΑΣ ΕΜΜΙΣΘ.ΠΡΟΣΩΠ.',
      Active: 'Ναι',
      Name: 'ΕΠΙΔΟΜΑΤΑ ΚΑΝΟΝ.ΑΔΕΙΑΣ ΕΜΜΙΣΘ.ΠΡΟΣΩΠ.',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-00-08-0000',
      label: 'ΑΠΟΖ.ΜΗ ΧΟΡΗΓ.ΑΔΕΙΩΝ ΕΜΜ.ΠΡΟΣ.',
      Active: 'Ναι',
      Name: 'ΑΠΟΖ.ΜΗ ΧΟΡΗΓ.ΑΔΕΙΩΝ ΕΜΜ.ΠΡΟΣ.',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '60-03-00-0000',
      label: 'ΕΡΓΟΔΟΤ.ΕΙΣΦΟΡ.ΙΚΑ ΕΜΜΙΣΘ.ΠΡΟΣΩΠΙΚΟΥ',
      Active: 'Ναι',
      Name: 'ΕΡΓΟΔΟΤ.ΕΙΣΦΟΡ.ΙΚΑ ΕΜΜΙΣΘ.ΠΡΟΣΩΠΙΚΟΥ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-00-03-0087',
      label: 'ΑΜΟΙΒΕΣ ΚΑΙ ΕΞΟΔΑ ΟΡΓΑΝΩΤΩΝ - ΜΕΛΕΤΗΤΩΝ - ΕΡΕΥΝΗΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ ΚΑΙ ΕΞΟΔΑ ΟΡΓΑΝΩΤΩΝ - ΜΕΛΕΤΗΤΩΝ - ΕΡΕΥΝΗΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-00-06-0087',
      label: 'ΑΜΟΙΒΕΣ ΚΑΙ ΕΞΟΔΑ ΛΟΓΙΣΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ ΚΑΙ ΕΞΟΔΑ ΛΟΓΙΣΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-01-01-0087',
      label: 'ΑΜΟΙΒΕΣ & ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ & ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-01-02-0087',
      label: 'ΑΜΟΙΒΕΣ ΣΕ ΕΤΑΙΡΙΕΣ ΜΕΛΕΤΩΝ ΤΕΧΝΙΚΩΝ ΕΡΓΩΝ ΕΞΩΤ.  ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ ΣΕ ΕΤΑΙΡΙΕΣ ΜΕΛΕΤΩΝ ΤΕΧΝΙΚΩΝ ΕΡΓΩΝ ΕΞΩΤ.  ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-02-01-0087',
      label: 'ΠΡΟΜΗΘΕΙΕΣ ΓΙΑ ΠΩΛΗΣΕΙΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΠΡΟΜΗΘΕΙΕΣ ΓΙΑ ΠΩΛΗΣΕΙΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-02-99-0087',
      label: 'ΛΟΙΠΕΣ ΠΡΟΜΗΘΕΙΕΣ ΤΡΙΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΕΣ ΠΡΟΜΗΘΕΙΕΣ ΤΡΙΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-03-00-0087',
      label: 'ΕΠΕΞΕΡΓΑΣΙΕΣ (FΑCΟΝ) ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΠΕΞΕΡΓΑΣΙΕΣ (FΑCΟΝ) ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-03-01-0087',
      label: 'ΑΜΟΙΒΕΣ ΜΗΧΑΝΟΓΡΑΦ.ΕΠΕΞΕΡΓ. ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ ΜΗΧΑΝΟΓΡΑΦ.ΕΠΕΞΕΡΓ. ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-30-00-2087',
      label: 'ΕΝΔΟΚΟΙΝΟΤΙΚΕΣ ΛΗΨΕΙΣ ΥΠΗΡΕΣΙΩΝ 24%',
      Active: 'Ναι',
      Name: 'ΕΝΔΟΚΟΙΝΟΤΙΚΕΣ ΛΗΨΕΙΣ ΥΠΗΡΕΣΙΩΝ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0487',
      ReverseVATAccount: '54-00-80-0487',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-90-10-0087',
      label: 'ΑΜΟΙΒΕΣ ΣΥΝΕΡΓΕΙΩΝ ΚΑΘΑΡΙΣΜΟΥ ΚΤΙΡΙΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ ΣΥΝΕΡΓΕΙΩΝ ΚΑΘΑΡΙΣΜΟΥ ΚΤΙΡΙΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '61-90-11-0087',
      label: 'ΑΜΟΙΒΕΣ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΑΜΟΙΒΕΣ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-00-00-0000',
      label: 'ΗΛΕΚΤΡΙΚΟ ΡΕΥΜΑ ΠΑΡΑΓΩΓΗΣ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΗΛΕΚΤΡΙΚΟ ΡΕΥΜΑ ΠΑΡΑΓΩΓΗΣ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-00-00-0025',
      label: 'ΗΛΕΚΤΡΙΚΟ ΡΕΥΜΑ ΠΑΡΑΓΩΓΗΣ ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΗΛΕΚΤΡΙΚΟ ΡΕΥΜΑ ΠΑΡΑΓΩΓΗΣ ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-03-00-0000',
      label: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-03-00-0024',
      label: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0024',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-03-00-0025',
      label: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-03-00-0087',
      label: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΤΗΛΕΦΩΝΙΚΑ-ΤΗΛΕΓΡΑΦΙΚΑ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-04-01-0000',
      label: 'ΕΝΟΙΚΙΑ ΚΤΙΡΙΩΝ-ΤΕΧΝΙΚΩΝ ΕΡΓΩΝ',
      Active: 'Ναι',
      Name: 'ΕΝΟΙΚΙΑ ΚΤΙΡΙΩΝ-ΤΕΧΝΙΚΩΝ ΕΡΓΩΝ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-05-06-0000',
      label: 'ΑΣΦΑΛΙΣΤΡΑ ΑΣΤΙΚΗΣ ΕΥΘΥΝΗΣ',
      Active: 'Ναι',
      Name: 'ΑΣΦΑΛΙΣΤΡΑ ΑΣΤΙΚΗΣ ΕΥΘΥΝΗΣ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-07-01-0087',
      label: 'ΚΤΙΡΙΩΝ-ΕΓΚΑΤ.ΚΤΙΡ-ΤΕΧΝΙΚ.ΕΡΓΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΚΤΙΡΙΩΝ-ΕΓΚΑΤ.ΚΤΙΡ-ΤΕΧΝΙΚ.ΕΡΓΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-07-02-0087',
      label: 'ΜΗΧΑΝΗΜ-ΤΕΧΝ.ΕΓΚΑΤ-ΛΟΙΠΟΥ ΜΗΧΑΝ.ΕΞΟΠΛ. ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΜΗΧΑΝΗΜ-ΤΕΧΝ.ΕΓΚΑΤ-ΛΟΙΠΟΥ ΜΗΧΑΝ.ΕΞΟΠΛ. ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-07-04-0087',
      label: 'ΕΠΙΣΚ.ΕΠΙΠΛΩΝ & ΛΟΙΠΟΥ ΕΞΟΠΛ. ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΠΙΣΚ.ΕΠΙΠΛΩΝ & ΛΟΙΠΟΥ ΕΞΟΠΛ. ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-00-0000',
      label: 'ΦΩΤΙΣΜΟΣ(ΠΛΗΝ ΗΛΕΚΤΡ.ΕΝΕΡΓΕΙΑΣ ΠΑΡΑΓΩΓΗΣ) ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΦΩΤΙΣΜΟΣ(ΠΛΗΝ ΗΛΕΚΤΡ.ΕΝΕΡΓΕΙΑΣ ΠΑΡΑΓΩΓΗΣ) ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-00-0025',
      label: 'ΦΩΤΙΣΜΟΣ(ΠΛΗΝ ΗΛΕΚΤΡ.ΕΝΕΡΓΕΙΑΣ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΦΩΤΙΣΜΟΣ(ΠΛΗΝ ΗΛΕΚΤΡ.ΕΝΕΡΓΕΙΑΣ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-00-0125',
      label: 'ΦΩΤΙΣΜΟΣ(ΠΛΗΝ ΗΛΕΚΤΡ.ΕΝΕΡΓΕΙΑΣ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 6% (ΦΠΑ ΜΗ ΕΚΠΤ.)',
      Active: 'Ναι',
      Name: 'ΦΩΤΙΣΜΟΣ(ΠΛΗΝ ΗΛΕΚΤΡ.ΕΝΕΡΓΕΙΑΣ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 6% (ΦΠΑ ΜΗ ΕΚΠΤ.)',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '63-98-08-0000',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Ναι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-01-0025',
      label: 'ΦΩΤΑΕΡΙΟ Η ΦΥΣΙΚΟ ΑΕΡΙΟ (ΠΛΗΝ ΦΩΤΑΕΡΙΟΥ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΦΩΤΑΕΡΙΟ Η ΦΥΣΙΚΟ ΑΕΡΙΟ (ΠΛΗΝ ΦΩΤΑΕΡΙΟΥ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-02-0000',
      label: 'ΥΔΡΕΥΣΗ(ΠΛΗΝ ΠΑΡΑΓΩΓΗΣ) ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΥΔΡΕΥΣΗ(ΠΛΗΝ ΠΑΡΑΓΩΓΗΣ) ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-02-0036',
      label: 'ΥΔΡΕΥΣΗ(ΠΛΗΝ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΥΔΡΕΥΣΗ(ΠΛΗΝ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-29-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '62-98-02-0087',
      label: 'ΥΔΡΕΥΣΗ(ΠΛΗΝ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΥΔΡΕΥΣΗ(ΠΛΗΝ ΠΑΡΑΓΩΓΗΣ) ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '63-04-00-0000',
      label: 'ΤΕΛΗ ΚΑΘΑΡΙΟΤ.& ΦΩΤΙΣΜΟΥ',
      Active: 'Ναι',
      Name: 'ΤΕΛΗ ΚΑΘΑΡΙΟΤ.& ΦΩΤΙΣΜΟΥ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '63-07-00-0000',
      label: 'ΤΡΕΧΩΝ ΦΟΡΟΣ (ΕΞΟΔΟ) ΠΕΡΙΟΔΟΥ',
      Active: 'Ναι',
      Name: 'ΤΡΕΧΩΝ ΦΟΡΟΣ (ΕΞΟΔΟ) ΠΕΡΙΟΔΟΥ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Όχι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '63-98-00-0000',
      label: 'ΧΑΡΤΟΣΗΜΟ ΜΙΣΘΩΜΑΤΩΝ',
      Active: 'Ναι',
      Name: 'ΧΑΡΤΟΣΗΜΟ ΜΙΣΘΩΜΑΤΩΝ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '63-98-08-0000',
      label: 'ΦΠΑ ΜΗ ΕΚΠΙΠΤ.(ΕΚΠΙΠΤ.ΣΤΗ ΦΟΡ.ΕΙΣΟΔΗΜ)',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΜΗ ΕΚΠΙΠΤ.(ΕΚΠΙΠΤ.ΣΤΗ ΦΟΡ.ΕΙΣΟΔΗΜ)',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Ναι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '63-98-09-0900',
      label: 'ΜΗ ΕΚΠΙΠΤ.ΦΠΑ(ΕΚΠΙΠΤ.ΠΟΣΟΣΤ.ΣΤΗ Φ.ΕΙΣ)',
      Active: 'Ναι',
      Name: 'ΜΗ ΕΚΠΙΠΤ.ΦΠΑ(ΕΚΠΙΠΤ.ΠΟΣΟΣΤ.ΣΤΗ Φ.ΕΙΣ)',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Ναι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '63-98-99-0000',
      label: 'ΛΟΙΠΟΙ ΦΟΡΟΙ-ΤΕΛΗ',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΟΙ ΦΟΡΟΙ-ΤΕΛΗ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-00-00-0000',
      label: 'ΕΞΟΔΑ ΚΙΝΗΣΗΣ ΜΕΤΑΦ.ΜΕΣΩΝ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΚΙΝΗΣΗΣ ΙΔΙΟΚΤ.ΜΕΤΑΦ.ΜΕΣΩΝ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-00-00-0087',
      label: 'ΕΞΟΔΑ ΚΙΝΗΣΗΣ ΙΔΙΟΚΤ.ΜΕΤΑΦ.ΜΕΣΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΚΙΝΗΣΗΣ ΙΔΙΟΚΤ.ΜΕΤΑΦ.ΜΕΣΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-00-02-0087',
      label: 'ΕΞΟΔΑ ΜΕΤΑΦ. ΥΛΙΚ-ΑΓΑΘ.ΑΓΟΡΩΝ ΜΕ ΜΕΤ. ΜΕΣΑ ΤΡΙΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΜΕΤΑΦ. ΥΛΙΚ-ΑΓΑΘ.ΑΓΟΡΩΝ ΜΕ ΜΕΤ. ΜΕΣΑ ΤΡΙΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-01-00-0000',
      label: 'ΕΞΟΔΑ ΤΑΞΙΔΙΩΝ ΕΣΩΤΕΡΙΚΟΥ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΤΑΞΙΔΙΩΝ ΕΣΩΤΕΡΙΚΟΥ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-01-01-0000',
      label: 'ΕΞΟΔΑ ΤΑΞΙΔΙΩΝ ΕΞΩΤΕΡΙΚΟΥ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΤΑΞΙΔΙΩΝ ΕΞΩΤΕΡΙΚΟΥ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-02-07-0087',
      label: 'ΕΞΟΔΑ ΠΡΟΒΟΛΗΣ ΔΙΑ ΛΟΙΠΩΝ ΜΕΘΟΔΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΠΡΟΒΟΛΗΣ ΔΙΑ ΛΟΙΠΩΝ ΜΕΘΟΔΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-02-99-0087',
      label: 'ΔΙΑΦΟΡΑ ΕΞΟΔΑ ΠΡΟΒΟΛΗΣ ΚΑΙ ΔΙΑΦΗΜΙΣΕΩΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΔΙΑΦΟΡΑ ΕΞΟΔΑ ΠΡΟΒΟΛΗΣ ΚΑΙ ΔΙΑΦΗΜΙΣΕΩΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-05-01-0000',
      label: 'ΣΥΝΔΡΟΜΕΣ-ΕΙΣΦΟΡ.ΣΕ ΕΠΑΓΓ.ΟΡΓΑΝ.',
      Active: 'Ναι',
      Name: 'ΣΥΝΔΡΟΜΕΣ-ΕΙΣΦΟΡ.ΣΕ ΕΠΑΓΓ.ΟΡΓΑΝ.',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-05-99-0087',
      label: 'ΛΟΙΠΕΣ ΣΥΝΔΡΟΜΕΣ-ΕΙΣΦΟΡΕΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΕΣ ΣΥΝΔΡΟΜΕΣ-ΕΙΣΦΟΡΕΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-07-00-0087',
      label: 'ΕΝΤΥΠΑ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΝΤΥΠΑ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-07-01-0087',
      label: 'ΥΛΙΚΑ ΠΟΛΛΑΠΛΩΝ ΕΚΤΥΠΩΣΕΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΥΛΙΚΑ ΠΟΛΛΑΠΛΩΝ ΕΚΤΥΠΩΣΕΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-07-03-0087',
      label: 'ΓΡΑΦΙΚΗ ΥΛΗ & ΛΟΙΠΑ ΥΛΙΚΑ ΓΡΑΦΕΙΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΓΡΑΦΙΚΗ ΥΛΗ & ΛΟΙΠΑ ΥΛΙΚΑ ΓΡΑΦΕΙΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-08-00-0087',
      label: 'ΚΑΥΣΙΜΑ ΚΑΙ ΛΟΙΠΑ ΥΛΙΚΑ ΘΕΡΜΑΝΣΕΩΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΚΑΥΣΙΜΑ ΚΑΙ ΛΟΙΠΑ ΥΛΙΚΑ ΘΕΡΜΑΝΣΕΩΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-08-01-0025',
      label: 'ΥΛΙΚΑ ΚΑΘΑΡΙΟΤΗΤΑΣ ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΥΛΙΚΑ ΚΑΘΑΡΙΟΤΗΤΑΣ ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-08-01-0087',
      label: 'ΥΛΙΚΑ ΚΑΘΑΡΙΟΤΗΤΑΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΥΛΙΚΑ ΚΑΘΑΡΙΟΤΗΤΑΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-08-02-0025',
      label: 'ΥΛΙΚΑ ΦΑΡΜΑΚΕΙΟΥ ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΥΛΙΚΑ ΦΑΡΜΑΚΕΙΟΥ ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-08-02-0087',
      label: 'ΥΛΙΚΑ ΦΑΡΜΑΚΕΙΟΥ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΥΛΙΚΑ ΦΑΡΜΑΚΕΙΟΥ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-08-06-0087',
      label: 'ΕΠΑΓΓΕΛΜΑΤΙΚΟΣ ΞΕΝΟΔΟΧΕΙΑΚΟΣ ΕΞΟΠΛΙΣΜΟΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΠΑΓΓΕΛΜΑΤΙΚΟΣ ΞΕΝΟΔΟΧΕΙΑΚΟΣ ΕΞΟΠΛΙΣΜΟΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-09-99-0087',
      label: 'ΕΞΟΔΑ ΛΟΙΠΩΝ ΔΗΜΟΣΙΕΥΣΕΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΛΟΙΠΩΝ ΔΗΜΟΣΙΕΥΣΕΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-00-0000',
      label: 'ΚΟΙΝΟΧΡΗΣΤΕΣ ΔΑΠΑΝΕΣ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΚΟΙΝΟΧΡΗΣΤΕΣ ΔΑΠΑΝΕΣ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-00-0025',
      label: 'ΚΟΙΝΟΧΡΗΣΤΕΣ ΔΑΠΑΝΕΣ ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΚΟΙΝΟΧΡΗΣΤΕΣ ΔΑΠΑΝΕΣ ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-00-0087',
      label: 'ΚΟΙΝΟΧΡΗΣΤΕΣ ΔΑΠΑΝΕΣ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΚΟΙΝΟΧΡΗΣΤΕΣ ΔΑΠΑΝΕΣ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-05-0000',
      label: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-05-0024',
      label: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0024',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-05-0025',
      label: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 6%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 6%',
      AccountType: 'Έξοδο',
      VATCategory: '6,00',
      VATAccount: '54-00-29-0025',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-05-0036',
      label: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 13%',
      AccountType: 'Έξοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-29-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-05-0087',
      label: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΕΞΟΔΑ ΔΙΑΦΟΡΩΝ ΤΡΙΤΩΝ ΦΠΑ 24%',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-29-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '64-98-99-0087',
      label: 'ΔΙΑΦΟΡΑ ΕΞΟΔΑ ΧΩΡΙΣ ΔΙΚΑΙΩΜΑ ΕΚΠΤΩΣΗΣ ΦΠΑ 24% ΝΑΙ ΜΥΦ',
      Active: 'Ναι',
      Name: 'ΔΙΑΦΟΡΑ ΕΞΟΔΑ ΧΩΡΙΣ ΔΙΚΑΙΩΜΑ ΕΚΠΤΩΣΗΣ ΦΠΑ 24% ΝΑΙ ΜΥΦ',
      AccountType: 'Έξοδο',
      VATCategory: '24,00',
      VATAccount: '63-98-08-0000',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Ναι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '65-98-99-0000',
      label: 'ΔΙΑΦΟΡΑ ΕΞΟΔΑ ΤΡΑΠΕΖΩΝ',
      Active: 'Ναι',
      Name: 'ΔΙΑΦΟΡΑ ΕΞΟΔΑ ΤΡΑΠΕΖΩΝ',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '70-00-00-0036',
      label: 'ΠΩΛΗΣ.ΕΜΠΟΡ.ΕΣΩΤΕΡ.ΧΟΝΔΡΙΚΑ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΠΩΛΗΣ.ΕΜΠΟΡ.ΕΣΩΤΕΡ.ΧΟΝΔΡΙΚΑ ΦΠΑ 13%',
      AccountType: 'Έσοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-70-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '70-00-00-0087',
      label: 'ΠΩΛΗΣ.ΕΜΠΟΡ.ΕΣΩΤΕΡ.ΧΟΝΔΡΙΚΑ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΠΩΛΗΣ.ΕΜΠΟΡ.ΕΣΩΤΕΡ.ΧΟΝΔΡΙΚΑ ΦΠΑ 24%',
      AccountType: 'Έσοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-70-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '70-03-00-0036',
      label: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΕΜΠΟΡΕΥΜΑΤΩΝ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΕΜΠΟΡΕΥΜΑΤΩΝ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 13%',
      AccountType: 'Έσοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-70-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '70-03-00-0087',
      label: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΕΜΠΟΡΕΥΜΑΤΩΝ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΕΜΠΟΡΕΥΜΑΤΩΝ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 24%',
      AccountType: 'Έσοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-70-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '73-00-00-0036',
      label: 'ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΧΟΝΔΡΙΚΗΣ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΧΟΝΔΡΙΚΗΣ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 13%',
      AccountType: 'Έσοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-73-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '73-00-00-0087',
      label: 'ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΧΟΝΔΡΙΚΗΣ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΧΟΝΔΡΙΚΗΣ ΕΣΩΤΕΡΙΚΟΥ ΦΠΑ 24%',
      AccountType: 'Έσοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-73-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '73-03-00-0000',
      label: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΑΝΕΥ ΦΠΑ',
      Active: 'Ναι',
      Name: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΑΝΕΥ ΦΠΑ',
      AccountType: 'Έσοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '73-03-00-0036',
      label: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΦΠΑ 13%',
      AccountType: 'Έσοδο',
      VATCategory: '13,00',
      VATAccount: '54-00-73-0036',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '73-03-00-0087',
      label: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΛΙΑΝΙΚΕΣ ΠΩΛΗΣΕΙΣ ΥΠΗΡΕΣΙΩΝ ΦΠΑ 24%',
      AccountType: 'Έσοδο',
      VATCategory: '24,00',
      VATAccount: '54-00-73-0087',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '81-00-00-0000',
      label: 'ΦΟΡΟΛΟΓΙΚΑ ΠΡΟΣΤΙΜΑ & ΠΡΟΣΑΥΞΗΣΕΙΣ',
      Active: 'Ναι',
      Name: 'ΦΟΡΟΛΟΓΙΚΑ ΠΡΟΣΤΙΜΑ & ΠΡΟΣΑΥΞΗΣΕΙΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '81-00-99-0000',
      label: 'ΛΟΙΠΑ ΕΚΤΑΚΤΑ & ΑΝΟΡΓ.ΕΞΟΔΑ',
      Active: 'Ναι',
      Name: 'ΛΟΙΠΑ ΕΚΤΑΚΤΑ & ΑΝΟΡΓ.ΕΞΟΔΑ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '86-02-01-0000',
      label: 'ΕΚΤΑΚΤΑ ΚΕΡΔΗ',
      Active: 'Ναι',
      Name: 'ΕΚΤΑΚΤΑ ΚΕΡΔΗ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    {
      value: '89-00-00-0000',
      label: 'ΙΣΟΛΟΓΙΣΜΟΣ ΑΝΟΙΓΜΑΤΟΣ ΧΡΗΣΗΣ',
      Active: 'Ναι',
      Name: 'ΙΣΟΛΟΓΙΣΜΟΣ ΑΝΟΙΓΜΑΤΟΣ ΧΡΗΣΗΣ',
      AccountType: 'Άλλο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
  ];

  const fpa = {
    '63-98-08-0000': {
      value: '63-98-08-0000',
      label: 'ΦΠΑ ΜΗ ΕΚΠΙΠΤ.(ΕΚΠΙΠΤ.ΣΤΗ ΦΟΡ.ΕΙΣΟΔΗΜ)',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΜΗ ΕΚΠΙΠΤ.(ΕΚΠΙΠΤ.ΣΤΗ ΦΟΡ.ΕΙΣΟΔΗΜ)',
      AccountType: 'Έξοδο',
      VATCategory: '',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Ναι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-73-0087': {
      value: '54-00-73-0087',
      label: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 24%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-73-0036': {
      value: '54-00-73-0036',
      label: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΠΩΛΗΣ.ΥΠΗΡΕΣ.ΜΕ ΦΠΑ 13%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-70-0087': {
      value: '54-00-70-0087',
      label: 'ΦΠΑ ΕΚΡΟΩΝ ΕΜΠΟΡΕΥΜ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΚΡΟΩΝ ΕΜΠΟΡΕΥΜ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-70-0036': {
      value: '54-00-70-0036',
      label: 'ΦΠΑ ΕΚΡΟΩΝ ΕΜΠΟΡΕΥΜΑΤΩΝ ΜΕ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΚΡΟΩΝ ΕΜΠΟΡΕΥΜΑΤΩΝ ΜΕ 13%',
      AccountType: 'Φ.Π.Α Εσόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-29-0487': {
      value: '54-00-29-0487',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΝΔΟΚ. ΛΗΨΕΙΣ ΥΠΗΡΕΣ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΝΔΟΚ. ΛΗΨΕΙΣ ΥΠΗΡΕΣ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-29-0087': {
      value: '54-00-29-0087',
      label: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-29-0036': {
      value: '54-00-29-0036',
      label: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-29-0025': {
      value: '54-00-29-0025',
      label: 'ΦΠΑ ΔΑΠΑΝΩΝ-ΕΞΟΔΩΝ 6%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΔΑΠΑΝΩΝ-ΕΞΟΔΩΝ 6%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-29-0024': {
      value: '54-00-29-0024',
      label: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΞΟΔΩΝ-ΔΑΠΑΝΩΝ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-28-0087': {
      value: '54-00-28-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΑΓΙΩΝ ΣΤΟΙΧΕΙΩΝ ΦΠΑ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΑΓΙΩΝ ΣΤΟΙΧΕΙΩΝ ΦΠΑ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-25-0087': {
      value: '54-00-25-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-25-0036': {
      value: '54-00-25-0036',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-25-0025': {
      value: '54-00-25-0025',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 6%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΛΟΙΠΩΝ ΑΓΟΡ.ΕΣΩΤΕΡΙΚΟΥ 6%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-24-0087': {
      value: '54-00-24-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-24-0036': {
      value: '54-00-24-0036',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 13%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΠΡΩΤ.& ΒΟΗΘ.ΥΛΩΝ ΕΣΩΤ. 13%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '13,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-24-0025': {
      value: '54-00-24-0025',
      label: "Φ.Π.Α ΑΓΟΡΩΝ Α'ΥΛΩΝ ΕΣΩΤΕΡ.6%",
      Active: 'Ναι',
      Name: "Φ.Π.Α ΑΓΟΡΩΝ Α'ΥΛΩΝ ΕΣΩΤΕΡ. 6%",
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '6,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
    '54-00-20-0087': {
      value: '54-00-20-0087',
      label: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 24%',
      Active: 'Ναι',
      Name: 'ΦΠΑ ΕΙΣΡΟΩΝ ΕΜΠΟΡ.ΕΣΩΤΕΡ. 24%',
      AccountType: 'Φ.Π.Α Εξόδου',
      VATCategory: '24,00',
      VATAccount: '',
      ReverseVATAccount: '',
      UpdatesMyf: 'Ναι',
      NonExclusiveVAT: 'Όχι',
      Behavior: 'Χρέωση/Πίστωση',
    },
  };

  // Parse the JSON string, or initialize with default values if empty or not present
  const initialRows = documents[0]?.accountant ? JSON.parse(documents[0].accountant) : null;

  // Check if initialRows is an empty array or null
  const isInitialEmpty = !initialRows || initialRows.length < 1;

  // Set default values if the initialRows array is empty or null
  const defaultRow = {
    selectedValue: null,
    amount: 0,
  };

  const [rows, setRows] = useState(isInitialEmpty ? [defaultRow] : initialRows);

  // setRows();
  console.log('PAPAJOHN');
  // console.log(formData);
  console.log(documents[0].accountant);
  console.log('PAPAJOHN');

  const updateFormData = () => {
    setFormData;
  };

  const addRow = () => {
    if (
      rows[rows.length - 1].selectedValue &&
      rows[rows.length - 1].selectedValue.VATAccount !== ''
    ) {
      setRows([
        ...rows,
        {
          selectedValue: fpa[rows[rows.length - 1].selectedValue.VATAccount],
          amount:
            Math.round(
              (parseFloat(rows[rows.length - 1].selectedValue.VATCategory) / 100) *
                parseFloat(rows[rows.length - 1].amount) *
                100,
            ) / 100,
        },
      ]);
    } else if (!rows[rows.length - 1].selectedValue || rows[rows.length - 1].amount == 0) {
      return;
    } else {
      if (rows[rows.length - 1].selectedValue.value === '50-00-00-0000') {
        rows.pop();
      }
      setRows([...rows, { selectedValue: null, amount: 0 }]);
    }
  };

  // const handleSave =

  const sumUp = () => {
    if (
      !rows[rows.length - 1].selectedValue ||
      (rows[rows.length - 1].selectedValue &&
        rows[rows.length - 1].selectedValue.value === '50-00-00-0000')
    ) {
      rows.pop();
      const sum = rows.reduce((accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.amount);
      }, 0);
      setRows([
        ...rows,
        {
          selectedValue: {
            value: '50-00-00-0000',
            label: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΣΩΤΕΡΙΚΟΥ',
            Active: 'Ναι',
            Name: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΣΩΤΕΡΙΚΟΥ',
            AccountType: 'Προμηθευτής',
            VATCategory: '',
            VATAccount: '',
            ReverseVATAccount: '',
            UpdatesMyf: 'Ναι',
            NonExclusiveVAT: 'Όχι',
            Behavior: 'Χρέωση/Πίστωση',
          },
          amount: sum,
        },
      ]);
    } else {
      const sum = rows.reduce((accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.amount);
      }, 0);
      setRows([
        ...rows,
        {
          selectedValue: {
            value: '50-00-00-0000',
            label: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΣΩΤΕΡΙΚΟΥ',
            Active: 'Ναι',
            Name: 'ΠΡΟΜΗΘΕΥΤΕΣ ΕΣΩΤΕΡΙΚΟΥ',
            AccountType: 'Προμηθευτής',
            VATCategory: '',
            VATAccount: '',
            ReverseVATAccount: '',
            UpdatesMyf: 'Ναι',
            NonExclusiveVAT: 'Όχι',
            Behavior: 'Χρέωση/Πίστωση',
          },
          amount: sum,
        },
      ]);
    }
  };

  const removeRow = index => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
    }
  };

  const handleSelectChange = (selectedOption, rowIndex) => {
    const newRows = [...rows];
    newRows[rowIndex].selectedValue = selectedOption;
    setRows(newRows);
  };

  const handleAmountChange = (event, rowIndex) => {
    const newRows = [...rows];
    newRows[rowIndex].amount = event.target.value;
    setRows(newRows);
  };

  // const useInitialCategorySetValue = () => {
  //   useEffect(() => {
  //     const categoryValue = form.getValues('category');
  //     form.setValue('category', categoryValue);
  //   }, [form, data, setFormData]);
  // };
  const POSITIVE_VALUE_INDICATOR = ['approved, success'];
  const NEGATIVE_VALUE_INDICATOR = ['revision', 'rejected'];
  const isDecisionPositive = (isDecisionComponent: boolean, value: string) => {
    return isDecisionComponent && value && POSITIVE_VALUE_INDICATOR.includes(value.toLowerCase());
  };
  const isDecisionNegative = (isDecisionComponent: boolean, value: string) => {
    return isDecisionComponent && value && NEGATIVE_VALUE_INDICATOR.includes(value.toLowerCase());
  };
  const defaultValues = formData?.reduce((acc, curr) => {
    acc[curr.title] = curr.value;

    return acc;
  }, {});
  const form = useForm({
    defaultValues,
  });
  const { mutate: mutateUpdateWorkflowById } = useUpdateWorkflowByIdMutation({
    workflowId,
  });
  const onMutateTaskDecisionById = ({
    context,
    action,
  }: {
    context: AnyRecord;
    action: Parameters<typeof mutateUpdateWorkflowById>[0]['action'];
  }) =>
    mutateUpdateWorkflowById({
      context,
      action,
    });
  const onSubmit: SubmitHandler<Record<PropertyKey, unknown>> = formData => {
    setEditable(false);
    const context = {
      documents: documents?.map(document => {
        if (document?.id !== valueId) return document;

        const properties = Object.keys(document?.propertiesSchema?.properties ?? {}).reduce(
          (acc, curr) => {
            if (!formData?.[curr]) return acc;
            acc[curr] = formData?.[curr];

            return acc;
          },
          {},
        );

        return {
          ...document,
          type: formData.type,
          category: formData.category,
          properties: properties,
          accountant: JSON.stringify(rows),
        };
      }),
    };

    return onMutateTaskDecisionById({
      context,
      action: 'update_document_properties',
    });
  };
  const isDecisionComponent = title === 'Decision';

  // useWatchDropdownOptions({ form, data, setFormData });
  // useInitialCategorySetValue({
  //   form,
  //   data,
  // });
  console.log('------------------*');

  console.log(data);
  console.log('------------------*');

  return (
    <>
      {id !== 'entity-details' && (
        <>
          <details open={authenticatedUser.firstName === 'accounting'} className={`group`}>
            <summary
              className={`mb-2 inline-flex cursor-pointer  list-none items-center gap-x-3 rounded-md p-1 pl-0 text-2xl font-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary`}
            >
              <span>Accounting</span>
              <ChevronDownSvg className={`rotate-180 d-5 group-open:rotate-0`} />
            </summary>
            <div>
              <div>
                <table style={{ tableLayout: 'fixed', width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '70%', maxWidth: '70%', textAlign: 'center' }}>
                        Category
                      </th>
                      <th style={{ width: '15%', maxWidth: '15%', textAlign: 'center' }}>Amount</th>
                      <th style={{ width: '5%', maxWidth: '5%', textAlign: 'center' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td style={{ maxWidth: '70%', paddingRight: '10px', fontSize: 'smaller' }}>
                          <Select
                            isDisabled={!editAccount}
                            formatOptionLabel={stateOptions => (
                              <div>
                                <div>{stateOptions.label}</div>
                                <div style={{ fontSize: 'small' }}>
                                  {stateOptions.value !== '' && `[${stateOptions.value}]`}
                                </div>
                              </div>
                            )}
                            options={stateOptions}
                            value={row.selectedValue}
                            onChange={selectedOption => handleSelectChange(selectedOption, index)}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: '10%',
                            textAlign: 'center',
                            paddingLeft: '20px',
                            padding: '10px',
                            fontSize: 'smaller',
                          }}
                        >
                          {/* Add padding here to create space */}
                          <Input
                            type={'number'}
                            className={ctw(
                              `p-1 disabled:cursor-pointer disabled:border-none disabled:bg-background disabled:opacity-100`,
                            )}
                            disabled={!editAccount}
                            autoComplete={'off'}
                            step={0.01}
                            value={row.amount}
                            onChange={event => handleAmountChange(event, index)}
                          />
                        </td>
                        {editAccount && (
                          <td
                            tabIndex={-1}
                            style={{ maxWidth: '5%', textAlign: 'center', padding: '10px' }}
                          >
                            <button tabIndex={-1} onClick={() => removeRow(index)}>
                              🗑️
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {editAccount ? (
                    <>
                      <div>
                        <Button className={'mb-8 mt-4'} onClick={addRow}>
                          ➕ ADD
                        </Button>

                        <Button className={'mb-8 mt-4 ml-3'} onClick={sumUp}>
                          🟰 SUMUP
                        </Button>
                      </div>
                      <div className={`flex justify-end`}>
                        <Button
                          onClick={() => {
                            form.handleSubmit(onSubmit)();
                            setEditAccount(false);
                          }}
                          className={`ms-auto mt-4`}
                        >
                          Save
                        </Button>

                        <Button
                          onClick={() => {
                            setEditAccount(false);
                          }}
                          className={`ms-auto ml-3 mt-4`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div></div>
                      {/* {data?.some(({ isEditable }) => isEditable) && ( */}
                      {authenticatedUser.firstName == 'accounting' &&
                        data?.some(({ isEditable }) => isEditable) && (
                          <Button onClick={() => setEditAccount(true)} className={'mb-8 mt-4'}>
                            Edit
                          </Button>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </details>
          <div
            data-orientation="horizontal"
            role="none"
            className="my-2 h-[1px] w-full shrink-0 bg-border"
          ></div>
        </>
      )}

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={`flex h-full flex-col`}>
          <legend className={ctw({ 'sr-only': id !== 'visible-title' }, 'mb-2 font-bold')}>
            {title}
          </legend>

          <div
            className={ctw(`grid grid-cols-2 gap-4`, {
              'grid-cols-3': id === 'entity-details',
            })}
          >
            {formData?.map(({ title, isEditable, type, format, pattern, value, dropdownOptions }) =>
              isDecisionComponent && !value ? null : (
                <FormField
                  key={title}
                  control={form.control}
                  name={title}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{toStartCase(camelCaseToSpace(title))}</FormLabel>
                      {false ? (
                        <></>
                      ) : (
                        <FormControl>
                          {title == 'description' ? (
                            <textarea
                              disabled={!editable}
                              className={ctw(
                                `flex w-full rounded-md border border-input bg-transparent p-1 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-pointer disabled:border-none disabled:bg-background disabled:opacity-100`,
                                {
                                  'font-bold text-success': isDecisionPositive(
                                    isDecisionComponent,
                                    field.value,
                                  ),
                                  'font-bold text-destructive': isDecisionNegative(
                                    isDecisionComponent,
                                    field.value,
                                  ),
                                },
                              )}
                              rows={8}
                              autoComplete={'off'}
                              style={{ width: '207%' }}
                              {...field}
                            />
                          ) : (
                            <Input
                              type={
                                !format
                                  ? title == 'cost'
                                    ? 'number'
                                    : type === 'string'
                                    ? 'text'
                                    : type
                                  : format
                              }
                              disabled={!editable}
                              className={ctw(
                                `p-1 disabled:cursor-pointer disabled:border-none disabled:bg-background disabled:opacity-100`,
                                {
                                  'font-bold text-success': isDecisionPositive(
                                    isDecisionComponent,
                                    field.value,
                                  ),
                                  'font-bold text-destructive': isDecisionNegative(
                                    isDecisionComponent,
                                    field.value,
                                  ),
                                },
                              )}
                              pattern={pattern}
                              autoComplete={'off'}
                              {...field}
                            />
                          )}
                        </FormControl>
                      )}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ),
            )}
          </div>
          {/* <Select defaultValue={stateOptions[0]} options={stateOptions} /> */}

          <div className={`flex justify-end`}>
            {data?.some(({ isEditable }) => isEditable) && !editable && (
              <Button
                onClick={() => {
                  setEditable(!editable);
                }}
                className={`ms-auto mt-3`}
              >
                Edit
              </Button>
            )}

            {data?.some(({ isEditable }) => isEditable) && editable && (
              <Button type="submit" className={`ms-auto mt-3`}>
                Save
              </Button>
            )}

            {data?.some(({ isEditable }) => isEditable) && editable && (
              <Button
                onClick={() => {
                  setEditable(!editable);
                }}
                className={`ms-auto ml-3 mt-3`}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      </Form>
    </>
  );
};
