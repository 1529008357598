import { z } from 'zod';

export const EnvSchema = z.object({
  MODE: z.enum(['development', 'production', 'test']),
  VITE_API_URL: z.string().url(),
  VITE_API_KEY: z.string(),
  VITE_AUTH_ENABLED: z.preprocess(JSON.parse, z.boolean().default(true)),
  VITE_MOCK_SERVER: z.preprocess(JSON.parse, z.boolean().default(true)),
  VITE_POLLING_INTERVAL: z.coerce
    .number()
    .transform(v => v * 1000)
    .or(z.literal(false))
    .catch(false),
  VITE_ASSIGNMENT_POLLING_INTERVAL: z.coerce
    .number()
    .transform(v => v * 1000)
    .or(z.literal(false))
    .catch(undefined),
  VITE_IMAGE_LOGO_URL: z.string().optional(),
});
