import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { IActionsProps } from './interfaces';
import { ResubmissionReason, useActions } from './hooks/useActions/useActions';
import { ctw } from '../../../../common/utils/ctw/ctw';
import { DropdownMenu } from '../../../../common/components/molecules/DropdownMenu/DropdownMenu';
import { DropdownMenuTrigger } from '../../../../common/components/molecules/DropdownMenu/DropdownMenu.Trigger';
import { DropdownMenuContent } from '../../../../common/components/molecules/DropdownMenu/DropdownMenu.Content';
import { DropdownMenuSeparator } from '../../../../common/components/molecules/DropdownMenu/DropdownMenu.Separator';
import { DropdownMenuLabel } from '../../../../common/components/molecules/DropdownMenu/DropdownMenu.Label';
import { DropdownMenuItem } from '../../../../common/components/molecules/DropdownMenu/DropdownMenu.Item';
import { DropdownMenuShortcut } from '../../../../common/components/molecules/DropdownMenu/DropDownMenu.Shortcut';
import { Action } from '../../../../common/enums';
import { Dialog } from '../../../../common/components/organisms/Dialog/Dialog';
import { DialogFooter } from '../../../../common/components/organisms/Dialog/Dialog.Footer';
import { DialogContent } from '../../../../common/components/organisms/Dialog/Dialog.Content';
import { DialogTrigger } from '../../../../common/components/organisms/Dialog/Dialog.Trigger';
import { DialogTitle } from '../../../../common/components/organisms/Dialog/Dialog.Title';
import { DialogDescription } from '../../../../common/components/organisms/Dialog/Dialog.Description';
import { DialogHeader } from '../../../../common/components/organisms/Dialog/Dialog.Header';
import { DialogClose } from '@radix-ui/react-dialog';
import { Select } from '../../../../common/components/atoms/Select/Select';
import {
  AssignButton,
  Assignee,
} from '../../../../common/components/atoms/AssignButton/AssignButton';
import * as HoverCard from '@radix-ui/react-hover-card';
import { Button } from '../../../../common/components/atoms/Button/Button';
import { SelectItem } from '../../../../common/components/atoms/Select/Select.Item';
import { SelectContent } from '../../../../common/components/atoms/Select/Select.Content';
import { SelectTrigger } from '../../../../common/components/atoms/Select/Select.Trigger';
import { SelectValue } from '../../../../common/components/atoms/Select/Select.Value';
import { Avatar } from '../../../../common/components/atoms/Avatar';
import { BottomActions } from '../../../../common/components/organisms/Header/Header.BottomActions';

import { Decisionz } from '../../Decisionz';
import { sleep } from '@ballerine/common';

/**
 * @description To be used by {@link Case}. Displays the entity's full name, avatar, and handles the reject/approve mutation.
 *
 * @param props
 * @param props.achain
 * @param props.id - The id of the entity, passed into the reject/approve mutation.
 * @param props.fullName - The full name of the entity.
 * @param props.avatarUrl - The entity's image url to pass into {@link Avatar}.
 *
 * @see {@link Case}
 * @see {@link Avatar}
 *
 * @constructor
 */
export const Actions: FunctionComponent<IActionsProps> = ({
  assigneeId,
  achain,
  id,
  fullName,
  showResolutionButtons = true,
}) => {
  const {
    onMutateUpdateWorkflowById,
    onMutateApproveEntity,
    onMutateRejectEntity,
    onMutateAssignWorkflow,
    debouncedIsLoadingApproveEntity,
    debouncedIsLoadingRejectEntity,
    debouncedIsLoadingAssignEntity,
    isLoading,
    isLoadingEntity,
    initials,
    canApprove,
    canReject,
    documentToResubmit,
    onDocumentToResubmitChange,
    resubmissionReason,
    onResubmissionReasonChange,
    caseState,
    authenticatedUser,
    assignees,
    isActionButtonDisabled,
    onTriggerAssignToMe,
    isAssignedToMe,
    hasDecision,
    status,
    iban,
    reason,
    amount,
    workflow,
  } = useActions({ workflowId: id, fullName });

  const wid = id;
  const [cd, setCd] = useState(false);
  const dataExists: boolean = workflow?.context?.entity?.paymentInfo;

  const [isaLoading, setIsaLoading] = useState(dataExists);
  const [response, setResponse] = useState<any | null>(null);

  const handlePayClick = async () => {
    // Set loading state and disable the button
    setIsaLoading(true);

    try {
      if (true) {
        // const url = 'https://pay.rayven.gr';
        // const url = 'http://localhost:5001';
        const url = 'https://rayven.gr/pay';

        const email = workflow.context.entity.data.email;
        const formData = workflow.context.documents[0].properties;
        console.log(formData);
        const payload = {
          trans: {
            iban: iban,
            amount: amount,
            reason: 'Rayven - Accounting 2.0',
            workflowId: wid,
            email: email,
            formData: formData,
          },
        };
        const options = {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Accept-Language': 'el-GR,el;q=0.9,en;q=0.8',
            Authorization: 'Api-Key secret',
            Connection: 'keep-alive',
            'Content-Type': 'application/json',
            Cookie:
              'session=eyJub3dJbk1pbnV0ZXMiOjI4MTk4Nzc0LCJwYXNzcG9ydCI6eyJ1c2VyIjp7ImlkIjoiY2xsOWMwdzEwMDAwNm90MWJidTd5MHIyaCIsImVtYWlsIjoiYWRtaW5AYWRtaW4uY29tIiwiZmlyc3ROYW1lIjoiS29zdGFzIiwibGFzdE5hbWUiOiJQYXBham9obiJ9fX0=; session.sig=lS4ehZLlumN0E3VxEOYpXSzyF_E',
            'If-None-Match': 'W/"6cb-9D5wLUNPesyRlQ+M6nr3CrjdmzA"',
            'Sec-Fetch-Dest': 'empty',
            'Sec-Fetch-Mode': 'no-cors',
            'Sec-Fetch-Site': 'same-site',
            'User-Agent':
              'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36',
            'sec-ch-ua': '"Not/A)Brand";v="99", "Google Chrome";v="115", "Chromium";v="115"',
            'sec-ch-ua-mobile': '?0',
            'sec-ch-ua-platform': '"macOS"',
          },
          body: JSON.stringify(payload),
        };

        // await sleep(1000);
        // await onMutateApproveEntity();

        await fetch(url, options)
          .then(resp => {
            // Check if the response status is OK (HTTP 200)
            if (resp.ok) {
              // Parse the response body as JSON
              const jsonData = resp.json();

              // Call the onMutateUpdateWorkflowById function with the parsed JSON data
              onMutateUpdateWorkflowById({ entity: { paymentInfo: jsonData } });
            } else {
              // Handle the case where the response status is not OK (e.g., error handling)
              console.error(`Request failed with status: ${resp.status}`);
            }
          })
          .then(json => console.log(json))
          .catch(err => console.error('error:', err));

        const payloada = {
          trans: {
            outcome: 'APPROVED!',
            email: email,
            formData: formData,
          },
        };

        const optionsa = {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Accept-Language': 'el-GR,el;q=0.9,en;q=0.8',
            Authorization: 'Api-Key secret',
            Connection: 'keep-alive',
            'Content-Type': 'application/json',
            'Sec-Fetch-Mode': 'no-cors',

            // ... (headers for the second fetch)
          },
          body: JSON.stringify(payloada),
        };

        //////////mail section
        //   const urla = 'https://pay.rayven.gr/mail';

        //   // Now, execute the second fetch
        //   fetch(urla, optionsa)
        //     .then(res => res.json())
        //     .then(json => console.log(json))
        //     .catch(err => console.error('error:', err));
        // }

        ////////////////

        // Assuming your API returns JSON, you can parse the response
        // const data = await apiResponse.json();

        // Set the response and reset loading state
        // setResponse(data);
        // setIsaLoading(false);

        // Additional logic based on the response if needed
      }
    } catch (error) {
      // Handle errors, reset loading state, or provide user feedback
      console.error('Error:', error);
      // setIsaLoading(false);
    } finally {
      // Reset loading state to enable the button
      setIsaLoading(false);
    }
  };

  function makePayment(iban, amount, wid, onMutateUpdateWorkflowById, workflow) {
    // const url = 'http://localhost:5001';
    const url = 'https://rayven.gr/pay';
    const email = workflow.context.entity.data.email;
    const formData = workflow.context.documents[0].properties;

    const payload = {
      trans: {
        iban: iban,
        amount: amount,
        reason: 'Rayven - Accounting 2.0',
        workflowId: wid,
        email: email,
        formData: formData,
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Accept-Language': 'el-GR,el;q=0.9,en;q=0.8',
        Authorization: 'Api-Key secret',
        Connection: 'keep-alive',
        'Content-Type': 'application/json',
        Cookie: 'your-cookie-value-here',
        'If-None-Match': 'W/"6cb-9D5wLUNPesyRlQ+M6nr3CrjdmzA"',
        'Sec-Fetch-Dest': 'empty',
        'Sec-Fetch-Mode': 'no-cors',
        'Sec-Fetch-Site': 'same-site',
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36',
        'sec-ch-ua': '"Not/A)Brand";v="99", "Google Chrome";v="115", "Chromium";v="115"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"macOS"',
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = fetch(url, options);

      if (response.ok) {
        const jsonData = response.json();
        onMutateUpdateWorkflowById({ entity: { paymentInfo: jsonData } });
      } else {
        console.error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // Example usage:
  // postDataToServer('your-iban', 'your-amount', 'your-wid', yourOnMutateUpdateWorkflowByIdFunction, yourWorkflowObject);

  const atrain = () => {
    // onMutateApproveEntity();
    setCd(true);
    handleApproveClick();

    id = authenticatedUser.phone;
    console.log(workflow.context.entity);
    if (authenticatedUser.phone == authenticatedUser.id) {
      // await makePayment(iban, amount, wid, onMutateUpdateWorkflowById, workflow);
      // // const url = 'https://pay.rayven.gr';
      // const url = 'http://localhost:5001';
      // const email = workflow.context.entity.data.email;
      // const formData = workflow.context.documents[0].properties;
      // console.log(formData);
      // const payload = {
      //   trans: {
      //     iban: iban,
      //     amount: amount,
      //     reason: 'Rayven - Accounting 2.0',
      //     workflowId: wid,
      //     email: email,
      //     formData: formData,
      //   },
      // };
      // const options = {
      //   method: 'POST',
      //   headers: {
      //     Accept: '*/*',
      //     'Accept-Language': 'el-GR,el;q=0.9,en;q=0.8',
      //     Authorization: 'Api-Key secret',
      //     Connection: 'keep-alive',
      //     'Content-Type': 'application/json',
      //     Cookie:
      //       'session=eyJub3dJbk1pbnV0ZXMiOjI4MTk4Nzc0LCJwYXNzcG9ydCI6eyJ1c2VyIjp7ImlkIjoiY2xsOWMwdzEwMDAwNm90MWJidTd5MHIyaCIsImVtYWlsIjoiYWRtaW5AYWRtaW4uY29tIiwiZmlyc3ROYW1lIjoiS29zdGFzIiwibGFzdE5hbWUiOiJQYXBham9obiJ9fX0=; session.sig=lS4ehZLlumN0E3VxEOYpXSzyF_E',
      //     'If-None-Match': 'W/"6cb-9D5wLUNPesyRlQ+M6nr3CrjdmzA"',
      //     'Sec-Fetch-Dest': 'empty',
      //     'Sec-Fetch-Mode': 'no-cors',
      //     'Sec-Fetch-Site': 'same-site',
      //     'User-Agent':
      //       'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36',
      //     'sec-ch-ua': '"Not/A)Brand";v="99", "Google Chrome";v="115", "Chromium";v="115"',
      //     'sec-ch-ua-mobile': '?0',
      //     'sec-ch-ua-platform': '"macOS"',
      //   },
      //   body: JSON.stringify(payload),
      // };

      // // await sleep(1000);
      // // await onMutateApproveEntity();

      // fetch(url, options)
      //   .then(resp => {
      //     // Check if the response status is OK (HTTP 200)
      //     if (resp.ok) {
      //       // Parse the response body as JSON
      //       const jsonData = resp.json();

      //       // Call the onMutateUpdateWorkflowById function with the parsed JSON data
      //       onMutateUpdateWorkflowById({ entity: { paymentInfo: jsonData } });
      //     } else {
      //       // Handle the case where the response status is not OK (e.g., error handling)
      //       console.error(`Request failed with status: ${resp.status}`);
      //     }
      //   })
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:', err));

      const payloada = {
        trans: {
          outcome: 'APPROVED!',
          email: email,
          formData: formData,
        },
      };

      const optionsa = {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Accept-Language': 'el-GR,el;q=0.9,en;q=0.8',
          Authorization: 'Api-Key secret',
          Connection: 'keep-alive',
          'Content-Type': 'application/json',
          'Sec-Fetch-Mode': 'no-cors',

          // ... (headers for the second fetch)
        },
        body: JSON.stringify(payloada),
      };

      const urla = 'https://rayven.gr/mail';

      // Now, execute the second fetch
      fetch(urla, optionsa)
        .then(res => res.json())
        .then(json => console.log(json))
        .catch(err => console.error('error:', err));
    } else {
      onMutateAssignWorkflow(id, !onTriggerAssignToMe);
    }
    setCd(false);
  };

  console.log('binchillaaa');
  console.log(iban);
  console.log(reason);
  console.log(amount);
  console.log(assigneeId);
  //console.log(achain);
  //console.log(achain.indexOf(''))
  console.log(authenticatedUser.phone);
  console.log('binchillaaa');

  const commentInputRef = useRef(null); // Ref to access the comment input

  // Event handler for the "Approve" button
  const handleRejectClick = () => {
    setCd(true);
    const comment = commentInputRef.current.textContent; // Get the comment from the contentEditable div
    // Make an API request to send the comment to the API
    console.log('printing comment');
    console.log(comment);
    const options = {
      timeZone: 'Europe/Athens', // Set the time zone to Greece
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };

    const now = new Date().toLocaleString('en-US', options);
    const context = {
      name: authenticatedUser.fullName,
      comment: comment,
      status: 'Reject',
      time: now,
    };
    const updatedApproverChain = [...(workflow?.context?.approverChain || []), context];

    onMutateUpdateWorkflowById({ approverChain: updatedApproverChain });
    onMutateRejectEntity({
      action: Action.REJECT,
    })();
    setCd(false);
  };

  // const handleApproveClick = () => {
  //   const options = {
  //     timeZone: 'Europe/Athens', // Set the time zone to Greece
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric',
  //     hour: 'numeric',
  //     minute: '2-digit',
  //     hour12: true,
  //   };

  //   const now = new Date().toLocaleString('en-US', options);
  //   const context = { name: authenticatedUser.fullName, comment: '', status: 'approve', time: now };
  //   const updatedApproverChain = [...(workflow?.context?.approverChain || []), context];

  //   onMutateUpdateWorkflowById({ approverChain: updatedApproverChain });
  //   if (authenticatedUser.id == authenticatedUser.phone) {
  //     // onMutateApproveEntity();
  //     handlePayClick();
  //     const accountant = '1JewQujwp1dKQquP3b6hUjsLOGk2';
  //     onMutateAssignWorkflow(accountant, !onTriggerAssignToMe);
  //   }
  // };
  const handleApproveClick = async () => {
    try {
      const options = {
        timeZone: 'Europe/Athens',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };

      const now = new Date().toLocaleString('en-US', options);
      const context = {
        name: authenticatedUser.fullName,
        comment: '',
        status: 'approve',
        time: now,
      };
      const updatedApproverChain = [...(workflow?.context?.approverChain || []), context];

      // First request
      await onMutateUpdateWorkflowById({ approverChain: updatedApproverChain });

      if (authenticatedUser.id == authenticatedUser.phone) {
        // Second request
        // onMutateApproveEntity();

        // Third request

        const accountant = '1JewQujwp1dKQquP3b6hUjsLOGk2';

        // Fourth request
        handlePayClick();
        await onMutateAssignWorkflow(accountant, !onTriggerAssignToMe);
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };

  const handleKeyPress = async e => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault(); // Prevent the newline character from being inserted
      await handleRejectClick(); // Call your asynchronous function to send the comment here
    }
  };

  return (
    <div className={`sticky top-0 z-50 col-span-2 bg-base-100 px-4 pt-4`}>
      <div className={`flex h-20 justify-between`}>
        <div className={`flex items-center space-x-8`}>
          <h2
            className={ctw(`text-2xl font-bold`, {
              'h-8 w-[24ch] animate-pulse rounded-md bg-gray-200 theme-dark:bg-neutral-focus':
                isLoadingEntity,
            })}
          >
            {fullName}
          </h2>
        </div>
        {showResolutionButtons && (
          <div className={`pe-[3.35rem] flex items-center space-x-6`}>
            {!hasDecision && !canApprove && status && false && (
              <AssignButton
                assignees={[
                  {
                    id: authenticatedUser?.id,
                    fullName: authenticatedUser?.fullName,
                  },
                ]}
                authenticatedUser={authenticatedUser}
                caseState={caseState}
                onAssigneeSelect={id => {
                  onMutateAssignWorkflow(id, onTriggerAssignToMe);
                }}
                buttonType={'Assign'}
                hasDecision={hasDecision}
              />
            )}
            {!hasDecision && !canApprove && false && (
              <AssignButton
                assignees={assignees as Assignee[]}
                authenticatedUser={authenticatedUser}
                caseState={caseState}
                onAssigneeSelect={id => {
                  onMutateAssignWorkflow(id, !onTriggerAssignToMe);
                }}
                buttonType={'Re-Assign'}
                hasDecision={hasDecision || status}
                //disabled={status}
              />
            )}
            {(workflow?.entity?.approvalState == 'APPROVED' ||
              (workflow?.assignee?.id === '1JewQujwp1dKQquP3b6hUjsLOGk2' && workflow?.assignee)) &&
              !workflow?.context?.entity?.paymentInfo &&
              authenticatedUser.roles.includes('admin') && (
                <Button
                  className={ctw({
                    // loading: debouncedIsLoadingRejectEntity,
                  })}
                  disabled={isaLoading || isLoading}
                  onClick={handlePayClick}
                  //disabled={actionButtonDisabled}
                  //disabled
                  // disabled={isLoading || !canReject || !onTriggerAssignToMe || status}
                >
                  Pay
                </Button>
              )}
            {(workflow?.entity?.approvalState == 'APPROVED' ||
              (workflow?.assignee?.id === '1JewQujwp1dKQquP3b6hUjsLOGk2' &&
                workflow?.assignee)) && (
              <Button
                className={ctw({
                  // loading: debouncedIsLoadingApproveEntity,
                })}
                variant={`success`}
                // disabled={isLoading || !canApprove}
                //disabled={status}
              >
                Approved!
              </Button>
            )}

            {!status && canReject && authenticatedUser.id != '1JewQujwp1dKQquP3b6hUjsLOGk2' && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    variant={`destructive`}
                    className={ctw({
                      // loading: debouncedIsLoadingRejectEntity,
                    })}
                    disabled={isLoading || !canReject || cd}
                  >
                    Reject
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <div>
                    <label className={`mb-1 font-bold`} htmlFor={`action`}>
                      Please review this submission
                    </label>
                  </div>

                  <div className="jfThread forActions">
                    <div className=" task isNotCompleted isExpanded hasBody isHeaderVisible">
                      <div className="jfThread-entry-body task isNotCompleted">
                        <div className="wmApproval-task-container isClean">
                          <div className="wmApproval-task-column forTask">
                            <div className="wmApproval-task-comment">
                              <div className="wmApproval-task-comment-title">Add Comment </div>
                              <div className="wmApproval-task-comment-textarea-wrapper">
                                <div
                                  data-placeholder="Add your comment here."
                                  data-testid="12030187645239269010-comment"
                                  contentEditable="true"
                                  className="wmApproval-task-comment-textarea"
                                  ref={commentInputRef}
                                  onKeyPress={handleKeyPress}
                                />
                                <div
                                  aria-hidden="true"
                                  className="wmApproval-task-comment-textarea-error"
                                >
                                  Comment is required.
                                </div>
                              </div>
                            </div>

                            <div className="wmApproval-error" aria-hidden="true">
                              Something went wrong. Please try again.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DialogFooter>
                    <DialogClose asChild>
                      <button
                        className={ctw(`btn-error btn justify-center`)}
                        // onClick={onMutateRejectEntity({
                        //   action: Action.REVISION,
                        // Currently hardcoded to documentOne.
                        // documentToRevision,
                        // revisionReason,
                        // })}
                        // disabled={!revisionReason}
                        onClick={handleRejectClick}
                        // onClick={onMutateRejectEntity({
                        //   action: Action.REJECT,
                        // })}
                      >
                        Confirm
                      </button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
            {!status && canApprove && authenticatedUser.id != '1JewQujwp1dKQquP3b6hUjsLOGk2' && (
              <HoverCard.Root openDelay={0} closeDelay={0}>
                <Button
                  className={ctw({
                    // loading: debouncedIsLoadingApproveEntity,
                  })}
                  variant={`success`}
                  disabled={isLoading || !canApprove || cd}
                  onClick={atrain}
                  //disabled={status}
                >
                  Approve
                </Button>

                {/* <Dialog>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <button
                        className="mantine-UnstyledButton-root mantine-ActionIcon-root mantine-1d0vl2q "
                        type="button"
                        style={{ maxWidth: '25px', minHeight: '35px' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-dots-vertical"
                          width="23"
                          height="23"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="12" cy="12" r="1" />
                          <circle cx="12" cy="19" r="1" />
                          <circle cx="12" cy="5" r="1" />
                        </svg>
                      </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className={`min-w-[16rem]`} align={`end`}>
                      <DropdownMenuLabel>Actions</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem className={`cursor-pointer`}>
                        <AssignButton
                          assignees={assignees as Assignee[]}
                          authenticatedUser={authenticatedUser}
                          caseState={caseState}
                          onAssigneeSelect={id => {
                            onMutateAssignWorkflow(id, !onTriggerAssignToMe);
                          }}
                          buttonType={'Re-Assign'}
                          hasDecision={hasDecision || status}
                          //disabled={status}
                        />
                        <DropdownMenuShortcut>Ctrl + J</DropdownMenuShortcut>
                      </DropdownMenuItem>
                      <DialogTrigger asChild>
                        <DropdownMenuItem className={`cursor-pointer`}>
                          <AssignButton
                            assignees={[
                              {
                                id: authenticatedUser?.id,
                                fullName: authenticatedUser?.fullName,
                              },
                            ]}
                            authenticatedUser={authenticatedUser}
                            caseState={caseState}
                            onAssigneeSelect={id => {
                              onMutateAssignWorkflow(id, onTriggerAssignToMe);
                            }}
                            buttonType={'Assign'}
                            hasDecision={hasDecision}
                          />
                          <DropdownMenuShortcut>Ctrl + R</DropdownMenuShortcut>
                        </DropdownMenuItem>
                      </DialogTrigger>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Request document re-submission</DialogTitle>
                      <DialogDescription>
                        This action will send a request to the user to re-submit their document.
                        State the reason for requesting a document re-submission.
                      </DialogDescription>
                    </DialogHeader>
                    <Select onValueChange={onResubmissionReasonChange}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Re-submission reason" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.values(ResubmissionReason).map(reason => {
                          const reasonWithSpace = reason.replace(/_/g, ' ').toLowerCase();
                          const capitalizedReason =
                            reasonWithSpace.charAt(0).toUpperCase() + reasonWithSpace.slice(1);

                          return (
                            <SelectItem
                              key={reason}
                              value={reason}
                              disabled={
                                isActionButtonDisabled && reason !== ResubmissionReason.BLURRY_IMAGE
                              }
                            >
                              {capitalizedReason}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <DialogFooter>
                      <DialogClose asChild>
                        <button
                          className={ctw(`btn-error btn justify-center`)}
                          onClick={onMutateRejectEntity({
                            action: Action.RESUBMIT,
                            // Currently hardcoded to documentOne.
                            documentToResubmit,
                            resubmissionReason,
                          })}
                          disabled={isActionButtonDisabled && !resubmissionReason}
                        >
                          Confirm
                        </button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog> */}

                <HoverCard.Portal>
                  <HoverCard.Content
                    className={`card card-compact mt-2 rounded-md border-neutral/10 bg-base-100 p-2 shadow theme-dark:border-neutral/50`}
                  >
                    <div className={`flex items-center space-x-2`}>
                      <kbd className="kbd">Ctrl</kbd>
                      <span>+</span>
                      <kbd className="kbd">A</kbd>
                    </div>
                  </HoverCard.Content>
                </HoverCard.Portal>
              </HoverCard.Root>
            )}
            {!status && authenticatedUser.firstName == 'accounting' && (
              <Button
                className={ctw({
                  // loading: debouncedIsLoadingApproveEntity,
                })}
                variant={`success`}
                disabled={isLoading || !canApprove}
                onClick={onMutateApproveEntity}
                //disabled={status}
              >
                Finalise
              </Button>
            )}

            <BottomActions />

            {/*<div className="dropdown-hover dropdown dropdown-bottom dropdown-end">*/}
            {/*  <EllipsisButton tabIndex={0} />*/}
            {/*  <ul*/}
            {/*    className={`dropdown-content menu h-72 w-48 space-y-2 rounded-md border border-neutral/10 bg-base-100 p-2 theme-dark:border-neutral/60`}*/}
            {/*  >*/}
            {/*    <li className={`disabled`}>*/}
            {/*      <button disabled>Coming Soon</button>*/}
            {/*    </li>*/}
            {/*    <li className={`disabled`}>*/}
            {/*      <button disabled>Coming Soon</button>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>
        )}
      </div>
    </div>
  );
};
