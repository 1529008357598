import { createQueryKeys } from '@lukemorales/query-key-factory';
import { fetchAuthenticatedUser } from './fetchers';

export const authQueryKeys = createQueryKeys('auth', {
  authenticatedUser: () => ({
    queryKey: ['session'],
    queryFn: async () => {
      try {
        // Attempt to fetch the authenticated user
        const userData = await fetchAuthenticatedUser();
        return userData;
      } catch (error) {
        // Handle the error gracefully
        console.error('Error fetching authenticated user:', error);

        // You can return a placeholder value or null to indicate that the data is not available
        return null;
      }
    },
    config: {
      // Configure caching and refetching options
      cacheTime: 10 * 60 * 1000, // Cache for 10 minutes
      staleTime: 5 * 60 * 1000, // Allow showing slightly stale data for 5 minutes
      refetchInterval: 60 * 60 * 1000, // Refetch every hour
      retry: 3, // Retry up to 3 times on failure
    },
  }),
});
