import { z } from 'zod';

export const AuthenticatedUserSchema = z
  .object({
    id: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    roles: z.array(z.string()),
  })
  .transform(({ firstName, lastName, roles, phone, ...other }) => ({
    ...other,
    firstName,
    lastName,
    phone,
    fullName: `${firstName} ${lastName}`,
    roles,
  }))
  .or(z.undefined());
