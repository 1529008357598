import { LogOutSvg, ExportSvg } from '../../atoms/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { ctw } from '../../../utils/ctw/ctw';
import { useSignOutMutation } from '../../../../domains/auth/hooks/mutations/useSignOutMutation/useSignOutMutation';
import { useAuthContext } from '../../../../domains/auth/context/AuthProvider/hooks/useAuthContext/useAuthContext';
import packageJson from '../../../../../package.json';
import { Avatar } from '../../atoms/Avatar';
import { useAuthenticatedUserQuery } from '../../../../domains/auth/hooks/queries/useAuthenticatedUserQuery/useAuthenticatedUserQuery';
import { Dialog } from '../../../../common/components/organisms/Dialog/Dialog';
import { DialogFooter } from '../../../../common/components/organisms/Dialog/Dialog.Footer';
import { DialogContent } from '../../../../common/components/organisms/Dialog/Dialog.Content';
import { DialogTrigger } from '../../../../common/components/organisms/Dialog/Dialog.Trigger';

import { DialogClose } from '@radix-ui/react-dialog';
import { CSVLink, CSVDownload } from 'react-csv';

export const BottomActions = () => {
  const [theme, setTheme] = useState(() => {
    const cachedTheme = localStorage.getItem(`theme`);

    if (!cachedTheme) return;

    return cachedTheme;
  });
  const onThemeChange = useCallback(
    (next: `dark` | `light`) => () => {
      localStorage.setItem(`theme`, next);
      document.documentElement.dataset.theme = next === `dark` ? `night` : `winter`;
      setTheme(next);
    },
    [],
  );
  const { mutate: signOut } = useSignOutMutation();
  const { signOutOptions } = useAuthContext();
  const onSignOut = useCallback(
    () =>
      signOut({
        redirect: signOutOptions?.redirect,
        callbackUrl: signOutOptions?.callbackUrl,
      }),
    [signOutOptions?.redirect, signOutOptions?.callbackUrl, signOut],
  );
  const { data: session } = useAuthenticatedUserQuery();

  const getTodayInGreece = () => {
    const today = new Date();
    // const options = { timeZone: 'Europe/Athens' };
    return today.toISOString().split('T')[0];
  };

  const [FromDate, setFromDate] = useState(getTodayInGreece());
  const [ToDate, setToDate] = useState(getTodayInGreece());

  // let csvData = [];
  const datz = FromDate === ToDate ? `${FromDate}` : `${FromDate}_${ToDate}`;
  const [csvData, setCsvData] = useState([]);
  const [fetched, setFetched] = useState(false);

  // useEffect(() => {
  //   // This will be called after the component renders
  //   // console.log(fetched);
  //   // console.log(csvData);
  //   if (downloaded) {
  //     setFetched(false);
  //     setDownloaded(false);
  //   }
  // }, [fetched, csvData, downloaded]);

  const convertObjectToArray = object => {
    return Object.keys(object).map(key => {
      return Object.values(object[key]);
    });
  };

  const fetchExport = async (): Promise<void> => {
    const url = 'https://gytnxhdeqrmdq45vaqtzx3cl2e0niyeb.lambda-url.eu-west-1.on.aws/';

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        FromDate: FromDate,
        ToDate: ToDate,
      }),
    };

    await fetch(url, options)
      .then(res => res.json())
      // .then(json => {
      //   // Assuming csvData is an array of arrays
      //   csvData = json;
      //   // console.log(csvData);
      // })
      .then(json => {
        console.log(json);
        const arrayOfArrays = convertObjectToArray(json);
        setCsvData(arrayOfArrays);
        // console.log(csvData);
        setFetched(true);
        // console.log(fetched);
        // console.log(typeof json);
      })

      // .then(json => setCsvData(json))
      // .then(() => console.log(csvData))
      .catch(err => console.error('error:', err));
  };

  return (
    <div className="navbar  z-10 flex  justify-between ">
      {/* Profile icon, opening menu on click */}
      <div className="dropdown-end dropdown ">
        <label tabIndex={0} className="btn-ghost btn-circle avatar btn">
          <div className="w-12 rounded-full">
            <img
              src="https://www.each.org.uk/app/uploads/2021/08/Directos-and-Trustees.png"
              alt="profile"
            />
          </div>
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu rounded-box menu-compact mt-3 w-52 bg-base-100 p-2 shadow"
        >
          <li>
            {session?.user?.firstName} {session?.user?.lastName}'s profile
          </li>

          {session?.user?.firstName === 'accounting' && (
            <li>
              <Dialog>
                <DialogTrigger asChild>
                  <a>
                    <ExportSvg />
                    Export
                  </a>
                </DialogTrigger>
                <DialogContent>
                  <div style={{ display: 'flex', gap: '10px', paddingTop: '30px' }}>
                    <div>
                      <label className={`mb-1 font-bold`} htmlFor={`action`}>
                        From Date
                      </label>
                      <input
                        type="date"
                        className="input input-md w-full  !ring-0 "
                        onChange={event => {
                          setFromDate(event.target.value);
                          setFetched(false);
                        }}
                        value={FromDate}
                      />
                    </div>
                    <div>
                      <label className={`mb-1 font-bold`} htmlFor={`action`}>
                        To Date
                      </label>
                      <input
                        type="date"
                        className="input input-md w-full  !ring-0"
                        onChange={event => {
                          setToDate(event.target.value);
                          setFetched(false);
                        }}
                        value={ToDate}
                      />
                    </div>
                  </div>

                  <DialogFooter>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      {!fetched && (
                        <button
                          className={ctw(`btn-success btn justify-center`)}
                          // onClick={onMutateRejectEntity({
                          //   action: Action.REVISION,
                          // Currently hardcoded to documentOne.
                          // documentToRevision,
                          // revisionReason,
                          // })}
                          // disabled={!revisionReason}
                          onClick={fetchExport}
                          // onClick={onMutateRejectEntity({
                          //   action: Action.REJECT,
                          // })}
                        >
                          Fetch
                        </button>
                      )}
                      {fetched && (
                        // <CSVDownload
                        //   data={csvData}
                        //   filename={`export_${datz}.csv`}
                        //   separator={';'}
                        //   enclosingCharacter={``}
                        //   target="_blank"
                        //   // onComplete={setDownloaded(true)}
                        // />
                        <button
                          // onClick={setDownloaded(true)}
                          className={ctw(`btn-success btn justify-center`)}
                        >
                          <DialogClose asChild>
                            <CSVLink
                              data={csvData}
                              filename={`export_${datz}.csv`}
                              separator={';'}
                              enclosingCharacter={``}
                            >
                              Download
                            </CSVLink>
                          </DialogClose>
                        </button>
                      )}
                    </div>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </li>
          )}
          <li>
            <a onClick={onSignOut}>
              <LogOutSvg /> Log out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  /*return (
    <div className={`mt-auto flex flex-col space-y-2`}>
      <div>
        <Avatar
          // src={avatarUrl}
          src={''}
          // placeholder={!avatarUrl ? initials : undefined}
          placeholder={'O'}
          alt={`${''}'s profile`}
          className={`ml-4 mr-2 d-6`}
          isLoading={false}
        />
        {session?.user?.firstName} {session?.user?.lastName}
      </div>
      <div className="dropdown-hover dropdown dropdown-top">
        <ul
          className={`dropdown-content menu h-72 w-48 space-y-2 rounded-md border border-neutral/10 p-2 text-base-content shadow theme-dark:border-neutral/60`}
        >
          <li className="menu-title">
            <span>Theme</span>
          </li>
          <li>
            <button
              className={ctw({
                active: theme === `dark`,
              })}
              onClick={onThemeChange(`dark`)}
            >
              Dark
            </button>
          </li>
          <li>
            <button
              className={ctw({
                active: theme === `light`,
              })}
              onClick={onThemeChange(`light`)}
            >
              Light
            </button>
          </li>
        </ul>
            </div>
      <button
        className={`btn-ghost btn-block btn justify-start gap-x-2 focus:outline-primary`}
        onClick={onSignOut}
      >
        <LogOutSvg /> Log out
      </button>
      <span className={`label-text-alt self-end`}>{`v${packageJson.version}`}</span>
    </div>
            );*/
};
