import { FunctionComponent, SVGProps } from 'react';
import { ctw } from '../../../utils/ctw/ctw';

/**
 * @description The Ballerine logo SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const BallerineLogo: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="220"
      height="34"
      viewBox="182.11 328.99 659.77 110.02"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <linearGradient x1="30%" x2="-10%" y1="120%" y2="30%" spreadMethod="pad">
        <stop offset="0%" stopColor="#fff" stopOpacity="1"></stop>
        <stop offset="100%" stopColor="#000" stopOpacity="1"></stop>
      </linearGradient>
      <linearGradient
        x1="30%"
        x2="-10%"
        y1="120%"
        y2="30%"
        gradientTransform="rotate(-30)"
        spreadMethod="pad"
      >
        <stop offset="0%" stopColor="#fff" stopOpacity="1"></stop>
        <stop offset="50%" stopColor="#ccc" stopOpacity="1"></stop>
        <stop offset="100%" stopColor="#000" stopOpacity="1"></stop>
      </linearGradient>
      <g>
        <image width="480" height="480" x="272" y="144" display="none"></image>
        <g>
          <image x="0" y="0" display="none"></image>
          <g
            style={{
              lineHeight: '1',
              fontVariantLigatures: 'normal',
              WebkitTextAlign: 'center',
              textAlign: 'center',
            }}
            fill="currentColor"
            stroke="#252322"
            strokeLinejoin="miter"
            strokeMiterlimit="2"
            strokeWidth="0"
            fontFamily="Brandmark Sans 21 Italic"
            fontSize="72"
            fontStyle="normal"
            fontWeight="normal"
            textAnchor="middle"
          >
            <path
              style={{
                lineHeight: '1',
                fontVariantLigatures: 'normal',
                WebkitTextAlign: 'center',
                textAlign: 'center',
              }}
              d="M290.45 354.842h-77.795l-30.541 84.168h25.851l7.094-19.48-10.58-6.372h32.344l16.473 25.852h25.851l-16.472-25.852h6.492c10.702 0 22.606-8.777 26.574-19.479l6.973-19.358c3.968-10.822-1.563-19.48-12.264-19.48zm-71.904 19.479h58.317l-7.094 19.358h-45.33l4.689-12.986z"
            ></path>
            <path
              style={{
                lineHeight: '1',
                fontVariantLigatures: 'normal',
                WebkitTextAlign: 'center',
                textAlign: 'center',
              }}
              d="M319.882 374.32l-23.567 64.69h25.851l21.163-58.317-10.581-6.372h58.316l-9.379 25.851H349.22c-3.366 9.379 3.608 19.359 13.588 19.359h11.783l-7.094 19.479h25.851l23.568-64.69c3.967-10.82-1.564-19.478-12.265-19.478h-58.316c-10.702 0-22.485 8.657-26.453 19.479z"
            ></path>
            <path
              style={{
                lineHeight: '1',
                fontVariantLigatures: 'normal',
                WebkitTextAlign: 'center',
                textAlign: 'center',
              }}
              d="M432.439 354.842l13.467 51.823-11.784 32.345h25.972l11.784-32.345 51.222-51.823h-25.972l-30.661 31.022-8.176-31.022z"
            ></path>
            <path
              style={{
                lineHeight: '1',
                fontVariantLigatures: 'normal',
                WebkitTextAlign: 'center',
                textAlign: 'center',
              }}
              d="M553.727 354.842h-25.852l8.176 84.168h25.972l91.864-110.02h-25.852l-68.777 82.244z"
            ></path>
            <path
              style={{
                lineHeight: '1',
                fontVariantLigatures: 'normal',
                WebkitTextAlign: 'center',
                textAlign: 'center',
              }}
              d="M632.111 439.01h64.81l7.094-19.48h-58.317l2.285-6.372-10.581-6.493h58.316l7.094-19.479h-45.33l2.405-6.493-10.702-6.372h71.303l7.094-19.48h-64.81c-10.7 0-22.484 8.658-26.452 19.48l-16.473 45.21c-3.968 10.822 1.563 19.479 12.264 19.479z"
            ></path>
            <path
              style={{
                lineHeight: '1',
                fontVariantLigatures: 'normal',
                WebkitTextAlign: 'center',
                textAlign: 'center',
              }}
              d="M770.704 354.842h-25.972l-30.54 84.168h25.851l19.479-53.507 25.851 53.507h25.852l30.661-84.168h-25.851l-19.48 53.507z"
            ></path>
          </g>
          <image x="0" y="0" display="none"></image>
        </g>
      </g>
    </svg>
  );
};

/**
 * @description The home SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const HomeSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
      />
    </svg>
  );
};

/**
 * @description The checked checkbox SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const CheckedSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
      />
    </svg>
  );
};

/**
 * @description The cog (settings) SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const CogSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );
};

/**
 * @description The log out SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const ExportSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      {...props}
    >
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
        <path d="M19 16v6m0 0 3-3m-3 3-3-3M4 6v6s0 3 7 3 7-3 7-3V6" />
        <path d="M11 3c7 0 7 3 7 3s0 3-7 3-7-3-7-3 0-3 7-3ZM11 21c-7 0-7-3-7-3v-6" />
      </g>
    </svg>
  );
};
export const LogOutSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
      />
    </svg>
  );
};

/**
 * @description The magnifying glass SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const MagnifyingGlassSvg: FunctionComponent<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={ctw('d-6', className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

/**
 * @description The filter SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const FilterSvg: FunctionComponent<SVGProps<SVGSVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={ctw('d-6', className)}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
      />
    </svg>
  );
};

/**
 * @description The sort SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const SortSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6771 9.23291C10.9592 8.95087 10.9592 8.49358 10.6771 8.21153C10.3951 7.92949 9.93781 7.92949 9.65576 8.21153L8.88867 8.97862L8.88867 4.72222C8.88867 4.32335 8.56532 4 8.16645 4C7.76758 4 7.44423 4.32335 7.44423 4.72222L7.44423 8.97862L6.67714 8.21153C6.39509 7.92949 5.93781 7.92949 5.65576 8.21153C5.37372 8.49358 5.37372 8.95087 5.65576 9.23291L7.65576 11.2329C7.93781 11.515 8.39509 11.515 8.67714 11.2329L10.6771 9.23291Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.211534 2.2124C-0.0705113 2.49445 -0.0705113 2.95173 0.211534 3.23378C0.493579 3.51582 0.950865 3.51582 1.23291 3.23378L2 2.46669L2 6.72309C2 7.12196 2.32335 7.44531 2.72222 7.44531C3.12109 7.44531 3.44444 7.12196 3.44444 6.72309L3.44444 2.46669L4.21153 3.23378C4.49358 3.51582 4.95087 3.51582 5.23291 3.23378C5.51496 2.95173 5.51496 2.49445 5.23291 2.2124L3.23291 0.212402C2.95087 -0.069643 2.49358 -0.069643 2.21153 0.212402L0.211534 2.2124Z"
        fill="currentColor"
      />
    </svg>
  );
};

/**
 * @description The rejected SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const RejectedSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
        clipRule="evenodd"
      />
    </svg>
  );
};

/**
 * @description The approved SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const ApprovedSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
};

/**
 * @description The chevron SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const ChevronDownSvg: FunctionComponent<SVGProps<SVGSVGElement>> = ({
  className,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={ctw('h-6 w-6', className)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

/**
 * @description The warning SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const WarningSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
  );
};

/**
 * @description An SVG of an 'X' from Heroicons.
 *
 * @see {@link https://heroicons.com/|Heroicons}
 */
export const XMarkSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
};

/**
 * @description The ellipsis SVG found in Ballerine's Figma design.
 * @param props
 * @constructor
 */
export const EllipsisSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="3"
      height="17"
      viewBox="0 0 3 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="8.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="15.5" r="1.5" fill="currentColor" />
    </svg>
  );
};

/**
 * @description A chevron left SVG from Heroicons.
 *
 * @see {@link https://heroicons.com/|Heroicons}
 */
export const ChevronLeftSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
  );
};

/**
 * @description A chevron right SVG from Heroicons.
 *
 * @see {@link https://heroicons.com/|Heroicons}
 */
export const ChevronRightSvg: FunctionComponent<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
  );
};

/**
 * @description An SVG of a checkmark from Heroicons.
 *
 * @see {@link https://heroicons.com/|Heroicons}
 */
export const CheckSvg: FunctionComponent<SVGProps<SVGSVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={ctw(`h-6 w-6`, className)}
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
  );
};

export const PhotoSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const NoCasesSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 81" fill="none" {...props}>
    <circle cx="45.8955" cy="42" r="39" fill="#D9D9D9" />
    <path
      d="M64.9549 27.3496H25.8361C23.9378 27.3496 22.1013 28.0247 20.6549 29.2541L2.89551 44.3496V67.5H87.8955V44.3496L70.1361 29.2541C68.6897 28.0247 66.8532 27.3496 64.9549 27.3496Z"
      fill="#E7E7E7"
    />
    <path
      d="M87.8955 44.3496L70.1361 29.2541C68.6897 28.0247 66.8532 27.3496 64.9549 27.3496H25.8361C23.9378 27.3496 22.1013 28.0247 20.6549 29.2541L2.89551 44.3496"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M1.89551 47C1.89551 44.7909 3.68637 43 5.89551 43H18.1336C21.028 43 23.7804 44.2541 25.6796 46.4382L29.1022 50.3741C31.3813 52.9951 34.6842 54.5 38.1574 54.5H52.6336C56.1068 54.5 59.4097 52.9951 61.6888 50.3741L65.1114 46.4382C67.0106 44.2541 69.763 43 72.6574 43H84.8955C87.1046 43 88.8955 44.7909 88.8955 47V64C88.8955 66.2091 87.1046 68 84.8955 68H5.89551C3.68637 68 1.89551 66.2091 1.89551 64V47Z"
      fill="white"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M73.8955 38C81.1492 36.6795 90.4869 28.6125 85.2113 20.2091C81.1492 13.7387 71.7109 17.4116 75.9266 24.0326C79.1106 29.0334 88.9059 25.3056 90.6338 17.1607M89.2736 8C89.5981 8.67957 89.8695 9.34662 90.0916 10M90.7924 13C90.8134 13.1679 90.8311 13.3345 90.8455 13.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle
      cx="86.2744"
      cy="3.41506"
      r="2.5"
      transform="rotate(-30 86.2744 3.41506)"
      fill="black"
    />
    <ellipse cx="91.6045" cy="2.64648" rx="3.5" ry="1.5" fill="black" />
    <ellipse
      cx="82.9445"
      cy="7.64665"
      rx="3.5"
      ry="1.5"
      transform="rotate(-60 82.9445 7.64665)"
      fill="black"
    />
  </svg>
);
